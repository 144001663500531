export const SCHOLARSHIP_RESULTS = [
  {
    "Sl no": 1,
    "CANDIDATE ID": 1001,
    R: 19,
    W: 21,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 2,
    "CANDIDATE ID": 1002,
    R: 28,
    W: 25,
    Total: 87,
    Percentage: 36.25,
  },
  {
    "Sl no": 3,
    "CANDIDATE ID": 1003,
    R: 21,
    W: 28,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 4,
    "CANDIDATE ID": 1004,
    R: 20,
    W: 13,
    Total: 67,
    Percentage: 27.917,
  },
  {
    "Sl no": 5,
    "CANDIDATE ID": 1005,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: -4.166666666666666,
  },
  {
    "Sl no": 6,
    "CANDIDATE ID": 1006,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 7,
    "CANDIDATE ID": 1007,
    R: 21,
    W: 35,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 8,
    "CANDIDATE ID": 1008,
    R: 18,
    W: 21,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 9,
    "CANDIDATE ID": 1009,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 10,
    "CANDIDATE ID": 1010,
    R: 12,
    W: 23,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 11,
    "CANDIDATE ID": 1011,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 12,
    "CANDIDATE ID": 1012,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 13,
    "CANDIDATE ID": 1013,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 14,
    "CANDIDATE ID": 1014,
    R: 23,
    W: 36,
    Total: 56,
    Percentage: 23.333333333333332,
  },
  {
    "Sl no": 15,
    "CANDIDATE ID": 1015,
    R: 30,
    W: 20,
    Total: 100,
    Percentage: 41.667,
  },
  {
    "Sl no": 16,
    "CANDIDATE ID": 1016,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 17,
    "CANDIDATE ID": 1017,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 18,
    "CANDIDATE ID": 1018,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.666666666666667,
  },
  {
    "Sl no": 19,
    "CANDIDATE ID": 1019,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 20,
    "CANDIDATE ID": 1020,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 21,
    "CANDIDATE ID": 1021,
    R: 14,
    W: 43,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 22,
    "CANDIDATE ID": 1022,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 23,
    "CANDIDATE ID": 1023,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 24,
    "CANDIDATE ID": 1024,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 25,
    "CANDIDATE ID": 1025,
    R: 15,
    W: 27,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 26,
    "CANDIDATE ID": 1026,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 27,
    "CANDIDATE ID": 1027,
    R: 16,
    W: 27,
    Total: 37,
    Percentage: 15.416666666666668,
  },
  {
    "Sl no": 28,
    "CANDIDATE ID": 1028,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 29,
    "CANDIDATE ID": 1029,
    R: 26,
    W: 31,
    Total: 73,
    Percentage: 30.416666666666664,
  },
  {
    "Sl no": 30,
    "CANDIDATE ID": 1030,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 31,
    "CANDIDATE ID": 1031,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 32,
    "CANDIDATE ID": 1032,
    R: 25,
    W: 34,
    Total: 66,
    Percentage: 27.5,
  },
  {
    "Sl no": 33,
    "CANDIDATE ID": 1033,
    R: 15,
    W: 40,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 34,
    "CANDIDATE ID": 1034,
    R: 13,
    W: 37,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 35,
    "CANDIDATE ID": 1035,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 36,
    "CANDIDATE ID": 1036,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 37,
    "CANDIDATE ID": 1037,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 38,
    "CANDIDATE ID": 1038,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 39,
    "CANDIDATE ID": 1039,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 40,
    "CANDIDATE ID": 1040,
    R: 10,
    W: 30,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 41,
    "CANDIDATE ID": 1041,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 42,
    "CANDIDATE ID": 1042,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 43,
    "CANDIDATE ID": 1043,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 44,
    "CANDIDATE ID": 1044,
    R: 17,
    W: 40,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 45,
    "CANDIDATE ID": 1045,
    R: 16,
    W: 37,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 46,
    "CANDIDATE ID": 1046,
    R: 16,
    W: 41,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 47,
    "CANDIDATE ID": 1047,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 48,
    "CANDIDATE ID": 1048,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 49,
    "CANDIDATE ID": 1049,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 50,
    "CANDIDATE ID": 1050,
    R: 19,
    W: 13,
    Total: 63,
    Percentage: 26.25,
  },
  {
    "Sl no": 51,
    "CANDIDATE ID": 1051,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 52,
    "CANDIDATE ID": 1052,
    R: 11,
    W: 36,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 53,
    "CANDIDATE ID": 1053,
    R: 15,
    W: 35,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 54,
    "CANDIDATE ID": 1054,
    R: 19,
    W: 29,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 55,
    "CANDIDATE ID": 1055,
    R: 11,
    W: 23,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 56,
    "CANDIDATE ID": 1056,
    R: 9,
    W: 21,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 57,
    "CANDIDATE ID": 1057,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 58,
    "CANDIDATE ID": 1058,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 59,
    "CANDIDATE ID": 1059,
    R: 24,
    W: 26,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 60,
    "CANDIDATE ID": 1060,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 61,
    "CANDIDATE ID": 1061,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 62,
    "CANDIDATE ID": 1063,
    R: 27,
    W: 32,
    Total: 76,
    Percentage: 31.667,
  },
  {
    "Sl no": 63,
    "CANDIDATE ID": 1064,
    R: 24,
    W: 29,
    Total: 67,
    Percentage: 27.917,
  },
  {
    "Sl no": 64,
    "CANDIDATE ID": 1065,
    R: 11,
    W: 36,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 65,
    "CANDIDATE ID": 1066,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 66,
    "CANDIDATE ID": 1067,
    R: 11,
    W: 29,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 67,
    "CANDIDATE ID": 1068,
    R: 9,
    W: 31,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 68,
    "CANDIDATE ID": 1069,
    R: 9,
    W: 46,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 69,
    "CANDIDATE ID": 1070,
    R: 17,
    W: 15,
    Total: 53,
    Percentage: 22.083,
  },
  {
    "Sl no": 70,
    "CANDIDATE ID": 1071,
    R: 19,
    W: 18,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 71,
    "CANDIDATE ID": 1072,
    R: 17,
    W: 26,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 72,
    "CANDIDATE ID": 1073,
    R: 19,
    W: 22,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 73,
    "CANDIDATE ID": 1074,
    R: 32,
    W: 13,
    Total: 115,
    Percentage: 47.917,
  },
  {
    "Sl no": 74,
    "CANDIDATE ID": 1075,
    R: 28,
    W: 20,
    Total: 92,
    Percentage: 38.333,
  },
  {
    "Sl no": 75,
    "CANDIDATE ID": 1076,
    R: 31,
    W: 17,
    Total: 107,
    Percentage: 44.583,
  },
  {
    "Sl no": 76,
    "CANDIDATE ID": 1077,
    R: 28,
    W: 14,
    Total: 98,
    Percentage: 40.833,
  },
  {
    "Sl no": 77,
    "CANDIDATE ID": 1078,
    R: 31,
    W: 14,
    Total: 110,
    Percentage: 45.833,
  },
  {
    "Sl no": 78,
    "CANDIDATE ID": 1079,
    R: 36,
    W: 11,
    Total: 133,
    Percentage: 55.417,
  },
  {
    "Sl no": 79,
    "CANDIDATE ID": 1080,
    R: 34,
    W: 13,
    Total: 123,
    Percentage: 51.25,
  },
  {
    "Sl no": 80,
    "CANDIDATE ID": 1081,
    R: 36,
    W: 23,
    Total: 121,
    Percentage: 50.417,
  },
  {
    "Sl no": 81,
    "CANDIDATE ID": 1082,
    R: 22,
    W: 24,
    Total: 64,
    Percentage: 26.666666666666668,
  },
  {
    "Sl no": 82,
    "CANDIDATE ID": 1083,
    R: 26,
    W: 21,
    Total: 83,
    Percentage: 34.583,
  },
  {
    "Sl no": 83,
    "CANDIDATE ID": 1084,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 84,
    "CANDIDATE ID": 1085,
    R: 10,
    W: 35,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 85,
    "CANDIDATE ID": 1086,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 86,
    "CANDIDATE ID": 1087,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 87,
    "CANDIDATE ID": 1088,
    R: 22,
    W: 35,
    Total: 53,
    Percentage: 22.083,
  },
  {
    "Sl no": 88,
    "CANDIDATE ID": 1089,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 89,
    "CANDIDATE ID": 1090,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 90,
    "CANDIDATE ID": 1091,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 91,
    "CANDIDATE ID": 1092,
    R: 24,
    W: 34,
    Total: 62,
    Percentage: 25.833,
  },
  {
    "Sl no": 92,
    "CANDIDATE ID": 1093,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 93,
    "CANDIDATE ID": 1094,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 94,
    "CANDIDATE ID": 1095,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 95,
    "CANDIDATE ID": 1096,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 96,
    "CANDIDATE ID": 1097,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 97,
    "CANDIDATE ID": 1098,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 98,
    "CANDIDATE ID": 1099,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 99,
    "CANDIDATE ID": 1100,
    R: 17,
    W: 12,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 100,
    "CANDIDATE ID": 1101,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 101,
    "CANDIDATE ID": 1102,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 102,
    "CANDIDATE ID": 1103,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 103,
    "CANDIDATE ID": 1104,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 104,
    "CANDIDATE ID": 1105,
    R: 20,
    W: 17,
    Total: 63,
    Percentage: 26.25,
  },
  {
    "Sl no": 105,
    "CANDIDATE ID": 1106,
    R: 16,
    W: 17,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 106,
    "CANDIDATE ID": 1107,
    R: 24,
    W: 35,
    Total: 61,
    Percentage: 25.416666666666664,
  },
  {
    "Sl no": 107,
    "CANDIDATE ID": 1108,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 108,
    "CANDIDATE ID": 1109,
    R: 32,
    W: 25,
    Total: 103,
    Percentage: 42.917,
  },
  {
    "Sl no": 109,
    "CANDIDATE ID": 1110,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 110,
    "CANDIDATE ID": 1111,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 111,
    "CANDIDATE ID": 1112,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 112,
    "CANDIDATE ID": 1113,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 113,
    "CANDIDATE ID": 1114,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 114,
    "CANDIDATE ID": 1115,
    R: 19,
    W: 39,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 115,
    "CANDIDATE ID": 1116,
    R: 30,
    W: 30,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 116,
    "CANDIDATE ID": 1117,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 117,
    "CANDIDATE ID": 1118,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 118,
    "CANDIDATE ID": 1119,
    R: 15,
    W: 25,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 119,
    "CANDIDATE ID": 1120,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 120,
    "CANDIDATE ID": 1121,
    R: 24,
    W: 31,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 121,
    "CANDIDATE ID": 1122,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 122,
    "CANDIDATE ID": 1123,
    R: 22,
    W: 37,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 123,
    "CANDIDATE ID": 1124,
    R: 21,
    W: 36,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 124,
    "CANDIDATE ID": 1125,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 125,
    "CANDIDATE ID": 1126,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 126,
    "CANDIDATE ID": 1127,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 127,
    "CANDIDATE ID": 1128,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 128,
    "CANDIDATE ID": 1129,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 129,
    "CANDIDATE ID": 1130,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 130,
    "CANDIDATE ID": 1131,
    R: 8,
    W: 23,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 131,
    "CANDIDATE ID": 1132,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 132,
    "CANDIDATE ID": 1133,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 133,
    "CANDIDATE ID": 1134,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 134,
    "CANDIDATE ID": 1135,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 135,
    "CANDIDATE ID": 1136,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 136,
    "CANDIDATE ID": 1137,
    R: 13,
    W: 21,
    Total: 31,
    Percentage: 12.916666666666668,
  },
  {
    "Sl no": 137,
    "CANDIDATE ID": 1138,
    R: 21,
    W: 28,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 138,
    "CANDIDATE ID": 1139,
    R: 30,
    W: 8,
    Total: 112,
    Percentage: 46.666666666666664,
  },
  {
    "Sl no": 139,
    "CANDIDATE ID": 1140,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 140,
    "CANDIDATE ID": 1141,
    R: 19,
    W: 24,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 141,
    "CANDIDATE ID": 1142,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 142,
    "CANDIDATE ID": 1143,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 143,
    "CANDIDATE ID": 1144,
    R: 20,
    W: 15,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 144,
    "CANDIDATE ID": 1145,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 145,
    "CANDIDATE ID": 1146,
    R: 18,
    W: 27,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 146,
    "CANDIDATE ID": 1147,
    R: 14,
    W: 26,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 147,
    "CANDIDATE ID": 1148,
    R: 20,
    W: 18,
    Total: 62,
    Percentage: 25.833,
  },
  {
    "Sl no": 148,
    "CANDIDATE ID": 1149,
    R: 30,
    W: 29,
    Total: 91,
    Percentage: 37.917,
  },
  {
    "Sl no": 149,
    "CANDIDATE ID": 1150,
    R: 5,
    W: 19,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 150,
    "CANDIDATE ID": 1151,
    R: 10,
    W: 28,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 151,
    "CANDIDATE ID": 1152,
    R: 14,
    W: 24,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 152,
    "CANDIDATE ID": 1153,
    R: 23,
    W: 34,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 153,
    "CANDIDATE ID": 1154,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 154,
    "CANDIDATE ID": 1155,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 155,
    "CANDIDATE ID": 1156,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 156,
    "CANDIDATE ID": 1157,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 157,
    "CANDIDATE ID": 1158,
    R: 20,
    W: 35,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 158,
    "CANDIDATE ID": 1159,
    R: 18,
    W: 30,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 159,
    "CANDIDATE ID": 1160,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 160,
    "CANDIDATE ID": 1161,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 161,
    "CANDIDATE ID": 1162,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 162,
    "CANDIDATE ID": 1163,
    R: 10,
    W: 42,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 163,
    "CANDIDATE ID": 1164,
    R: 14,
    W: 35,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 164,
    "CANDIDATE ID": 1164,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 165,
    "CANDIDATE ID": 1165,
    R: 15,
    W: 31,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 166,
    "CANDIDATE ID": 1166,
    R: 9,
    W: 37,
    Total: -1,
    Percentage: null,
  },
  {
    "Sl no": 167,
    "CANDIDATE ID": 1167,
    R: 8,
    W: 33,
    Total: -1,
    Percentage: null,
  },
  {
    "Sl no": 168,
    "CANDIDATE ID": 1168,
    R: 7,
    W: 29,
    Total: -1,
    Percentage: null,
  },
  {
    "Sl no": 169,
    "CANDIDATE ID": 1169,
    R: 8,
    W: 15,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 170,
    "CANDIDATE ID": 1170,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 171,
    "CANDIDATE ID": 1171,
    R: 13,
    W: 27,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 172,
    "CANDIDATE ID": 1172,
    R: 14,
    W: 24,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 173,
    "CANDIDATE ID": 1173,
    R: 15,
    W: 25,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 174,
    "CANDIDATE ID": 1174,
    R: 11,
    W: 34,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 175,
    "CANDIDATE ID": 1175,
    R: 13,
    W: 35,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 176,
    "CANDIDATE ID": 1176,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 177,
    "CANDIDATE ID": 1177,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 178,
    "CANDIDATE ID": 1187,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 179,
    "CANDIDATE ID": 1188,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 180,
    "CANDIDATE ID": 1189,
    R: 14,
    W: 20,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 181,
    "CANDIDATE ID": 1190,
    R: 29,
    W: 21,
    Total: 95,
    Percentage: 39.583,
  },
  {
    "Sl no": 182,
    "CANDIDATE ID": 1191,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 183,
    "CANDIDATE ID": 1192,
    R: 17,
    W: 36,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 184,
    "CANDIDATE ID": 1193,
    R: 8,
    W: 29,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 185,
    "CANDIDATE ID": 1194,
    R: 18,
    W: 29,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 186,
    "CANDIDATE ID": 1195,
    R: 15,
    W: 26,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 187,
    "CANDIDATE ID": 1196,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 188,
    "CANDIDATE ID": 1197,
    R: 7,
    W: 18,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 189,
    "CANDIDATE ID": 1198,
    R: 11,
    W: 19,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 190,
    "CANDIDATE ID": 1199,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 191,
    "CANDIDATE ID": 1200,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 192,
    "CANDIDATE ID": 1201,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833333333333334,
  },
  {
    "Sl no": 193,
    "CANDIDATE ID": 1202,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 194,
    "CANDIDATE ID": 1203,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 195,
    "CANDIDATE ID": 1204,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 196,
    "CANDIDATE ID": 1205,
    R: 19,
    W: 38,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 197,
    "CANDIDATE ID": 1206,
    R: 13,
    W: 42,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 198,
    "CANDIDATE ID": 1207,
    R: 9,
    W: 16,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 199,
    "CANDIDATE ID": 1208,
    R: 17,
    W: 40,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 200,
    "CANDIDATE ID": 1209,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 201,
    "CANDIDATE ID": 1210,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 202,
    "CANDIDATE ID": 1211,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 203,
    "CANDIDATE ID": 1212,
    R: 12,
    W: 36,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 204,
    "CANDIDATE ID": 1213,
    R: 9,
    W: 39,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 205,
    "CANDIDATE ID": 1214,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 206,
    "CANDIDATE ID": 1215,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 207,
    "CANDIDATE ID": 1216,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 208,
    "CANDIDATE ID": 1217,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 209,
    "CANDIDATE ID": 1218,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 210,
    "CANDIDATE ID": 1219,
    R: 10,
    W: 46,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 211,
    "CANDIDATE ID": 1220,
    R: 21,
    W: 20,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 212,
    "CANDIDATE ID": 1221,
    R: 16,
    W: 38,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 213,
    "CANDIDATE ID": 1222,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 214,
    "CANDIDATE ID": 1223,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 215,
    "CANDIDATE ID": 1224,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 216,
    "CANDIDATE ID": 1225,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 217,
    "CANDIDATE ID": 1226,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 218,
    "CANDIDATE ID": 1227,
    R: 10,
    W: 45,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 219,
    "CANDIDATE ID": 1228,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 220,
    "CANDIDATE ID": 1229,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 221,
    "CANDIDATE ID": 1230,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 222,
    "CANDIDATE ID": 1231,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 223,
    "CANDIDATE ID": 1232,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 224,
    "CANDIDATE ID": 1233,
    R: 29,
    W: 18,
    Total: 98,
    Percentage: 40.833,
  },
  {
    "Sl no": 225,
    "CANDIDATE ID": 1234,
    R: 20,
    W: 33,
    Total: 47,
    Percentage: 15.417,
  },
  {
    "Sl no": 226,
    "CANDIDATE ID": 1235,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 227,
    "CANDIDATE ID": 1236,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 228,
    "CANDIDATE ID": 1237,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 229,
    "CANDIDATE ID": 1238,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 230,
    "CANDIDATE ID": 1239,
    R: 14,
    W: 43,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 231,
    "CANDIDATE ID": 1240,
    R: 22,
    W: 20,
    Total: 68,
    Percentage: 28.333,
  },
  {
    "Sl no": 232,
    "CANDIDATE ID": 1241,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 233,
    "CANDIDATE ID": 1242,
    R: 32,
    W: 18,
    Total: 110,
    Percentage: 45.833,
  },
  {
    "Sl no": 234,
    "CANDIDATE ID": 1243,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 235,
    "CANDIDATE ID": 1244,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 236,
    "CANDIDATE ID": 1245,
    R: 14,
    W: 39,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 237,
    "CANDIDATE ID": 1246,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 238,
    "CANDIDATE ID": 1247,
    R: 18,
    W: 38,
    Total: 34,
    Percentage: 14.166666666666666,
  },
  {
    "Sl no": 239,
    "CANDIDATE ID": 1248,
    R: 21,
    W: 23,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 240,
    "CANDIDATE ID": 1248,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 241,
    "CANDIDATE ID": 1249,
    R: 19,
    W: 28,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 242,
    "CANDIDATE ID": 1250,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 243,
    "CANDIDATE ID": 1251,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 244,
    "CANDIDATE ID": 1252,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 245,
    "CANDIDATE ID": 1253,
    R: 13,
    W: 45,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 246,
    "CANDIDATE ID": 1254,
    R: 22,
    W: 21,
    Total: 67,
    Percentage: 27.917,
  },
  {
    "Sl no": 247,
    "CANDIDATE ID": 1255,
    R: 8,
    W: 37,
    Total: 19,
    Percentage: 7.916666666666666,
  },
  {
    "Sl no": 248,
    "CANDIDATE ID": 1256,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 249,
    "CANDIDATE ID": 1257,
    R: 14,
    W: 35,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 250,
    "CANDIDATE ID": 1258,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 251,
    "CANDIDATE ID": 1259,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 252,
    "CANDIDATE ID": 1260,
    R: 17,
    W: 29,
    Total: 39,
    Percentage: 10.417,
  },
  {
    "Sl no": 253,
    "CANDIDATE ID": 1261,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 254,
    "CANDIDATE ID": 1262,
    R: 17,
    W: 40,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 255,
    "CANDIDATE ID": 1263,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 256,
    "CANDIDATE ID": 1264,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 257,
    "CANDIDATE ID": 1265,
    R: 12,
    W: 30,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 258,
    "CANDIDATE ID": 1266,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 259,
    "CANDIDATE ID": 1267,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 260,
    "CANDIDATE ID": 1268,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 261,
    "CANDIDATE ID": 1269,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 262,
    "CANDIDATE ID": 1270,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 263,
    "CANDIDATE ID": 1271,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 264,
    "CANDIDATE ID": 1272,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 265,
    "CANDIDATE ID": 1273,
    R: 11,
    W: 48,
    Total: -4,
    Percentage: null,
  },
  {
    "Sl no": 266,
    "CANDIDATE ID": 1274,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 267,
    "CANDIDATE ID": 1275,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 268,
    "CANDIDATE ID": 1276,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 269,
    "CANDIDATE ID": 1277,
    R: 21,
    W: 30,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 270,
    "CANDIDATE ID": 1278,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 271,
    "CANDIDATE ID": 1279,
    R: 12,
    W: 35,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 272,
    "CANDIDATE ID": 1280,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 273,
    "CANDIDATE ID": 1281,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 274,
    "CANDIDATE ID": 1282,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 275,
    "CANDIDATE ID": 1283,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 276,
    "CANDIDATE ID": 1284,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 277,
    "CANDIDATE ID": 1284,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 278,
    "CANDIDATE ID": 1286,
    R: 12,
    W: 45,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 279,
    "CANDIDATE ID": 1287,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 280,
    "CANDIDATE ID": 1288,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 281,
    "CANDIDATE ID": 1289,
    R: 16,
    W: 25,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 282,
    "CANDIDATE ID": 1290,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 283,
    "CANDIDATE ID": 1291,
    R: 20,
    W: 37,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 284,
    "CANDIDATE ID": 1292,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 285,
    "CANDIDATE ID": 1293,
    R: 8,
    W: 16,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 286,
    "CANDIDATE ID": 1294,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 287,
    "CANDIDATE ID": 1295,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 288,
    "CANDIDATE ID": 1296,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 289,
    "CANDIDATE ID": 1297,
    R: 29,
    W: 31,
    Total: 85,
    Percentage: 35.417,
  },
  {
    "Sl no": 290,
    "CANDIDATE ID": 1298,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 291,
    "CANDIDATE ID": 1299,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 292,
    "CANDIDATE ID": 1300,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.666666666666664,
  },
  {
    "Sl no": 293,
    "CANDIDATE ID": 1300,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 294,
    "CANDIDATE ID": 1301,
    R: 9,
    W: 25,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 295,
    "CANDIDATE ID": 1302,
    R: 18,
    W: 30,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 296,
    "CANDIDATE ID": 1303,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 297,
    "CANDIDATE ID": 1304,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 298,
    "CANDIDATE ID": 1305,
    R: 17,
    W: 21,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 299,
    "CANDIDATE ID": 1306,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 300,
    "CANDIDATE ID": 1307,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 301,
    "CANDIDATE ID": 1308,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 302,
    "CANDIDATE ID": 1309,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 303,
    "CANDIDATE ID": 1310,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 304,
    "CANDIDATE ID": 1311,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 305,
    "CANDIDATE ID": 1312,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 306,
    "CANDIDATE ID": 1313,
    R: 18,
    W: 22,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 307,
    "CANDIDATE ID": 1314,
    R: 18,
    W: 39,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 308,
    "CANDIDATE ID": 1315,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 309,
    "CANDIDATE ID": 1316,
    R: 13,
    W: 16,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 310,
    "CANDIDATE ID": 1317,
    R: 9,
    W: 17,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 311,
    "CANDIDATE ID": 1318,
    R: 14,
    W: 41,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 312,
    "CANDIDATE ID": 1319,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 313,
    "CANDIDATE ID": 1320,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 314,
    "CANDIDATE ID": 1321,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 315,
    "CANDIDATE ID": 1322,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 316,
    "CANDIDATE ID": 1323,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 317,
    "CANDIDATE ID": 1324,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 318,
    "CANDIDATE ID": 1325,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 319,
    "CANDIDATE ID": 1326,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 320,
    "CANDIDATE ID": 1327,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 321,
    "CANDIDATE ID": 1328,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 322,
    "CANDIDATE ID": 1329,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 323,
    "CANDIDATE ID": 1330,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 324,
    "CANDIDATE ID": 1331,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 325,
    "CANDIDATE ID": 1332,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 326,
    "CANDIDATE ID": 1333,
    R: 18,
    W: 33,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 327,
    "CANDIDATE ID": 1334,
    R: 18,
    W: 33,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 328,
    "CANDIDATE ID": 1335,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 329,
    "CANDIDATE ID": 1336,
    R: 10,
    W: 33,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 330,
    "CANDIDATE ID": 1337,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 331,
    "CANDIDATE ID": 1338,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 332,
    "CANDIDATE ID": 1339,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 333,
    "CANDIDATE ID": 1340,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 334,
    "CANDIDATE ID": 1341,
    R: 24,
    W: 31,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 335,
    "CANDIDATE ID": 1342,
    R: 10,
    W: 46,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 336,
    "CANDIDATE ID": 1343,
    R: 19,
    W: 37,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 337,
    "CANDIDATE ID": 1344,
    R: 12,
    W: 32,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 338,
    "CANDIDATE ID": 1345,
    R: 20,
    W: 32,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 339,
    "CANDIDATE ID": 1346,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 340,
    "CANDIDATE ID": 1347,
    R: 20,
    W: 30,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 341,
    "CANDIDATE ID": 1348,
    R: 14,
    W: 26,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 342,
    "CANDIDATE ID": 1349,
    R: 17,
    W: 28,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 343,
    "CANDIDATE ID": 1351,
    R: 17,
    W: 40,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 344,
    "CANDIDATE ID": 1352,
    R: 24,
    W: 27,
    Total: 69,
    Percentage: 28.75,
  },
  {
    "Sl no": 345,
    "CANDIDATE ID": 1353,
    R: 16,
    W: 37,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 346,
    "CANDIDATE ID": 1354,
    R: 6,
    W: 36,
    Total: -12,
    Percentage: null,
  },
  {
    "Sl no": 347,
    "CANDIDATE ID": 1355,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 348,
    "CANDIDATE ID": 1356,
    R: 18,
    W: 34,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 349,
    "CANDIDATE ID": 1357,
    R: 14,
    W: 35,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 350,
    "CANDIDATE ID": 1358,
    R: 19,
    W: 41,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 351,
    "CANDIDATE ID": 1359,
    R: 6,
    W: 37,
    Total: -13,
    Percentage: null,
  },
  {
    "Sl no": 352,
    "CANDIDATE ID": 1360,
    R: 5,
    W: 36,
    Total: -16,
    Percentage: null,
  },
  {
    "Sl no": 353,
    "CANDIDATE ID": 1361,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 354,
    "CANDIDATE ID": 1362,
    R: 21,
    W: 37,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 355,
    "CANDIDATE ID": 1363,
    R: 11,
    W: 13,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 356,
    "CANDIDATE ID": 1364,
    R: 20,
    W: 23,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 357,
    "CANDIDATE ID": 1365,
    R: 6,
    W: 24,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 358,
    "CANDIDATE ID": 1366,
    R: 27,
    W: 23,
    Total: 85,
    Percentage: 35.417,
  },
  {
    "Sl no": 359,
    "CANDIDATE ID": 1367,
    R: 23,
    W: 36,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 360,
    "CANDIDATE ID": 1368,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 361,
    "CANDIDATE ID": 1369,
    R: 20,
    W: 16,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 362,
    "CANDIDATE ID": 1370,
    R: 18,
    W: 23,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 363,
    "CANDIDATE ID": 1371,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 364,
    "CANDIDATE ID": 1372,
    R: 24,
    W: 15,
    Total: 81,
    Percentage: 33.75,
  },
  {
    "Sl no": 365,
    "CANDIDATE ID": 1373,
    R: 10,
    W: 13,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 366,
    "CANDIDATE ID": 1374,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 367,
    "CANDIDATE ID": 1375,
    R: 10,
    W: 28,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 368,
    "CANDIDATE ID": 1376,
    R: 9,
    W: 11,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 369,
    "CANDIDATE ID": 1377,
    R: 17,
    W: 20,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 370,
    "CANDIDATE ID": 1378,
    R: 16,
    W: 32,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 371,
    "CANDIDATE ID": 1379,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 372,
    "CANDIDATE ID": 1380,
    R: 23,
    W: 36,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 373,
    "CANDIDATE ID": 1381,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 374,
    "CANDIDATE ID": 1382,
    R: 12,
    W: 31,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 375,
    "CANDIDATE ID": 1383,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 376,
    "CANDIDATE ID": 1384,
    R: 13,
    W: 45,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 377,
    "CANDIDATE ID": 1385,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 378,
    "CANDIDATE ID": 1386,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 379,
    "CANDIDATE ID": 1387,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 380,
    "CANDIDATE ID": 1388,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 381,
    "CANDIDATE ID": 1389,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 382,
    "CANDIDATE ID": 1390,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 383,
    "CANDIDATE ID": 1391,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 384,
    "CANDIDATE ID": 1392,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 385,
    "CANDIDATE ID": 1393,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 386,
    "CANDIDATE ID": 1394,
    R: 18,
    W: 21,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 387,
    "CANDIDATE ID": 1395,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 388,
    "CANDIDATE ID": 1396,
    R: 24,
    W: 32,
    Total: 64,
    Percentage: 26.666666666666668,
  },
  {
    "Sl no": 389,
    "CANDIDATE ID": 1397,
    R: 16,
    W: 35,
    Total: 29,
    Percentage: 12.083333333333334,
  },
  {
    "Sl no": 390,
    "CANDIDATE ID": 1398,
    R: 18,
    W: 26,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 391,
    "CANDIDATE ID": 1399,
    R: 28,
    W: 31,
    Total: 81,
    Percentage: 33.75,
  },
  {
    "Sl no": 392,
    "CANDIDATE ID": 1400,
    R: 25,
    W: 30,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 393,
    "CANDIDATE ID": 1401,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 394,
    "CANDIDATE ID": 1402,
    R: 8,
    W: 14,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 395,
    "CANDIDATE ID": 1403,
    R: 12,
    W: 21,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 396,
    "CANDIDATE ID": 1404,
    R: 10,
    W: 25,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 397,
    "CANDIDATE ID": 1405,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 398,
    "CANDIDATE ID": 1406,
    R: 10,
    W: 34,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 399,
    "CANDIDATE ID": 1407,
    R: 12,
    W: 22,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 400,
    "CANDIDATE ID": 1408,
    R: 18,
    W: 39,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 401,
    "CANDIDATE ID": 1409,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 402,
    "CANDIDATE ID": 1409,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 403,
    "CANDIDATE ID": 1410,
    R: 18,
    W: 35,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 404,
    "CANDIDATE ID": 1411,
    R: 17,
    W: 21,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 405,
    "CANDIDATE ID": 1412,
    R: 17,
    W: 34,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 406,
    "CANDIDATE ID": 1413,
    R: 15,
    W: 34,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 407,
    "CANDIDATE ID": 1414,
    R: 22,
    W: 33,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 408,
    "CANDIDATE ID": 1415,
    R: 19,
    W: 33,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 409,
    "CANDIDATE ID": 1416,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 410,
    "CANDIDATE ID": 1417,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 411,
    "CANDIDATE ID": 1418,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 412,
    "CANDIDATE ID": 1419,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 413,
    "CANDIDATE ID": 1420,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 414,
    "CANDIDATE ID": 1421,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 415,
    "CANDIDATE ID": 1422,
    R: 20,
    W: 36,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 416,
    "CANDIDATE ID": 1423,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 417,
    "CANDIDATE ID": 1424,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 418,
    "CANDIDATE ID": 1425,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 419,
    "CANDIDATE ID": 1426,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 420,
    "CANDIDATE ID": 1427,
    R: 17,
    W: 33,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 421,
    "CANDIDATE ID": 1428,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 422,
    "CANDIDATE ID": 1429,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 423,
    "CANDIDATE ID": 1430,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 424,
    "CANDIDATE ID": 1431,
    R: 25,
    W: 32,
    Total: 68,
    Percentage: 28.333,
  },
  {
    "Sl no": 425,
    "CANDIDATE ID": 1432,
    R: 14,
    W: 31,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 426,
    "CANDIDATE ID": 1433,
    R: 18,
    W: 36,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 427,
    "CANDIDATE ID": 1434,
    R: 25,
    W: 22,
    Total: 78,
    Percentage: 32.5,
  },
  {
    "Sl no": 428,
    "CANDIDATE ID": 1435,
    R: 19,
    W: 39,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 429,
    "CANDIDATE ID": 1436,
    R: 17,
    W: 38,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 430,
    "CANDIDATE ID": 1437,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 431,
    "CANDIDATE ID": 1438,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 432,
    "CANDIDATE ID": 1439,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 433,
    "CANDIDATE ID": 1440,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 434,
    "CANDIDATE ID": 1441,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 435,
    "CANDIDATE ID": 1442,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 436,
    "CANDIDATE ID": 1443,
    R: 11,
    W: 39,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 437,
    "CANDIDATE ID": 1444,
    R: 10,
    W: 31,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 438,
    "CANDIDATE ID": 1445,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 439,
    "CANDIDATE ID": 1446,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 440,
    "CANDIDATE ID": 1447,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 441,
    "CANDIDATE ID": 1448,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 442,
    "CANDIDATE ID": 1449,
    R: 9,
    W: 15,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 443,
    "CANDIDATE ID": 1450,
    R: 9,
    W: 26,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 444,
    "CANDIDATE ID": 1451,
    R: 9,
    W: 11,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 445,
    "CANDIDATE ID": 1452,
    R: 13,
    W: 35,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 446,
    "CANDIDATE ID": 1453,
    R: 9,
    W: 19,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 447,
    "CANDIDATE ID": 1454,
    R: 13,
    W: 26,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 448,
    "CANDIDATE ID": 1455,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 449,
    "CANDIDATE ID": 1456,
    R: 7,
    W: 15,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 450,
    "CANDIDATE ID": 1457,
    R: 10,
    W: 16,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 451,
    "CANDIDATE ID": 1458,
    R: 9,
    W: 13,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 452,
    "CANDIDATE ID": 1459,
    R: 15,
    W: 40,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 453,
    "CANDIDATE ID": 1460,
    R: 9,
    W: 32,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 454,
    "CANDIDATE ID": 1461,
    R: 9,
    W: 9,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 455,
    "CANDIDATE ID": 1462,
    R: 3,
    W: 14,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 456,
    "CANDIDATE ID": 1463,
    R: 3,
    W: 18,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 457,
    "CANDIDATE ID": 1464,
    R: 1,
    W: 10,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 458,
    "CANDIDATE ID": 1465,
    R: 8,
    W: 11,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 459,
    "CANDIDATE ID": 1466,
    R: 10,
    W: 18,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 460,
    "CANDIDATE ID": 1467,
    R: 3,
    W: 14,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 461,
    "CANDIDATE ID": 1468,
    R: 14,
    W: 37,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 462,
    "CANDIDATE ID": 1469,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 463,
    "CANDIDATE ID": 1470,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 464,
    "CANDIDATE ID": 1471,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 465,
    "CANDIDATE ID": 1472,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 466,
    "CANDIDATE ID": 1473,
    R: 14,
    W: 25,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 467,
    "CANDIDATE ID": 1474,
    R: 21,
    W: 37,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 468,
    "CANDIDATE ID": 1475,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 469,
    "CANDIDATE ID": 1476,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 470,
    "CANDIDATE ID": 1477,
    R: 12,
    W: 25,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 471,
    "CANDIDATE ID": 1478,
    R: 10,
    W: 38,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 472,
    "CANDIDATE ID": 1479,
    R: 20,
    W: 34,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 473,
    "CANDIDATE ID": 1480,
    R: 10,
    W: 25,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 474,
    "CANDIDATE ID": 1481,
    R: 7,
    W: 20,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 475,
    "CANDIDATE ID": 1482,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 476,
    "CANDIDATE ID": 1483,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 477,
    "CANDIDATE ID": 1484,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 478,
    "CANDIDATE ID": 1485,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 479,
    "CANDIDATE ID": 1486,
    R: 8,
    W: 28,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 480,
    "CANDIDATE ID": 1487,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 481,
    "CANDIDATE ID": 1488,
    R: 16,
    W: 12,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 482,
    "CANDIDATE ID": 1489,
    R: 24,
    W: 35,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 483,
    "CANDIDATE ID": 1490,
    R: 15,
    W: 20,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 484,
    "CANDIDATE ID": 1491,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 485,
    "CANDIDATE ID": 1492,
    R: 21,
    W: 37,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 486,
    "CANDIDATE ID": 1493,
    R: 16,
    W: 41,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 487,
    "CANDIDATE ID": 1494,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 488,
    "CANDIDATE ID": 1495,
    R: 13,
    W: 42,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 489,
    "CANDIDATE ID": 1501,
    R: 13,
    W: 26,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 490,
    "CANDIDATE ID": 1502,
    R: 9,
    W: 29,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 491,
    "CANDIDATE ID": 1503,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 492,
    "CANDIDATE ID": 1504,
    R: 22,
    W: 37,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 493,
    "CANDIDATE ID": 1505,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 494,
    "CANDIDATE ID": 1506,
    R: 26,
    W: 33,
    Total: 71,
    Percentage: 29.583333333333332,
  },
  {
    "Sl no": 495,
    "CANDIDATE ID": 1507,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 496,
    "CANDIDATE ID": 1508,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 497,
    "CANDIDATE ID": 1509,
    R: 14,
    W: 36,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 498,
    "CANDIDATE ID": 1510,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 499,
    "CANDIDATE ID": 1511,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 500,
    "CANDIDATE ID": 1512,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 501,
    "CANDIDATE ID": 1513,
    R: 13,
    W: 25,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 502,
    "CANDIDATE ID": 1514,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 503,
    "CANDIDATE ID": 1515,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 504,
    "CANDIDATE ID": 1516,
    R: 21,
    W: 37,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 505,
    "CANDIDATE ID": 1517,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 506,
    "CANDIDATE ID": 1518,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 507,
    "CANDIDATE ID": 1519,
    R: 15,
    W: 31,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 508,
    "CANDIDATE ID": 1520,
    R: 13,
    W: 26,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 509,
    "CANDIDATE ID": 1521,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 510,
    "CANDIDATE ID": 1522,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 511,
    "CANDIDATE ID": 1523,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 512,
    "CANDIDATE ID": 1524,
    R: 8,
    W: 51,
    Total: -19,
    Percentage: null,
  },
  {
    "Sl no": 513,
    "CANDIDATE ID": 1525,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 514,
    "CANDIDATE ID": 1526,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 515,
    "CANDIDATE ID": 1527,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 516,
    "CANDIDATE ID": 1528,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 517,
    "CANDIDATE ID": 1529,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 518,
    "CANDIDATE ID": 1530,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 519,
    "CANDIDATE ID": 1531,
    R: 18,
    W: 42,
    Total: 13,
    Percentage: 5.416666666666667,
  },
  {
    "Sl no": 520,
    "CANDIDATE ID": 1532,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 521,
    "CANDIDATE ID": 1533,
    R: 15,
    W: 39,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 522,
    "CANDIDATE ID": 1534,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 523,
    "CANDIDATE ID": 1535,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 524,
    "CANDIDATE ID": 1536,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 525,
    "CANDIDATE ID": 1537,
    R: 17,
    W: 37,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 526,
    "CANDIDATE ID": 1538,
    R: 17,
    W: 24,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 527,
    "CANDIDATE ID": 1539,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 528,
    "CANDIDATE ID": 1540,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 529,
    "CANDIDATE ID": 1541,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 530,
    "CANDIDATE ID": 1542,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 531,
    "CANDIDATE ID": 1543,
    R: 22,
    W: 17,
    Total: 71,
    Percentage: 29.583,
  },
  {
    "Sl no": 532,
    "CANDIDATE ID": 1544,
    R: 21,
    W: 33,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 533,
    "CANDIDATE ID": 1545,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 534,
    "CANDIDATE ID": 1546,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 535,
    "CANDIDATE ID": 1547,
    R: 18,
    W: 21,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 536,
    "CANDIDATE ID": 1548,
    R: 21,
    W: 23,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 537,
    "CANDIDATE ID": 1549,
    R: 15,
    W: 26,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 538,
    "CANDIDATE ID": 1550,
    R: 14,
    W: 25,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 539,
    "CANDIDATE ID": 1551,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 540,
    "CANDIDATE ID": 1552,
    R: 25,
    W: 15,
    Total: 85,
    Percentage: 35.417,
  },
  {
    "Sl no": 541,
    "CANDIDATE ID": 1553,
    R: 26,
    W: 16,
    Total: 88,
    Percentage: 36.667,
  },
  {
    "Sl no": 542,
    "CANDIDATE ID": 1554,
    R: 18,
    W: 35,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 543,
    "CANDIDATE ID": 1555,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 544,
    "CANDIDATE ID": 1556,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 545,
    "CANDIDATE ID": 1557,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 546,
    "CANDIDATE ID": 1558,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 547,
    "CANDIDATE ID": 1559,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 548,
    "CANDIDATE ID": 1560,
    R: 22,
    W: 11,
    Total: 77,
    Percentage: 32.083,
  },
  {
    "Sl no": 549,
    "CANDIDATE ID": 1561,
    R: 12,
    W: 30,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 550,
    "CANDIDATE ID": 1562,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 551,
    "CANDIDATE ID": 1563,
    R: 20,
    W: 30,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 552,
    "CANDIDATE ID": 1564,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 553,
    "CANDIDATE ID": 1565,
    R: 22,
    W: 32,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 554,
    "CANDIDATE ID": 1566,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 555,
    "CANDIDATE ID": 1567,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 556,
    "CANDIDATE ID": 1568,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 557,
    "CANDIDATE ID": 1569,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 558,
    "CANDIDATE ID": 1571,
    R: 21,
    W: 20,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 559,
    "CANDIDATE ID": 1572,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 560,
    "CANDIDATE ID": 1573,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 561,
    "CANDIDATE ID": 1574,
    R: 25,
    W: 24,
    Total: 76,
    Percentage: 31.667,
  },
  {
    "Sl no": 562,
    "CANDIDATE ID": 1575,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 563,
    "CANDIDATE ID": 1576,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 564,
    "CANDIDATE ID": 1577,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 565,
    "CANDIDATE ID": 1578,
    R: 19,
    W: 37,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 566,
    "CANDIDATE ID": 1579,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 567,
    "CANDIDATE ID": 1580,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 568,
    "CANDIDATE ID": 1581,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 569,
    "CANDIDATE ID": 1582,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 570,
    "CANDIDATE ID": 1583,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 571,
    "CANDIDATE ID": 1584,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 572,
    "CANDIDATE ID": 1585,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 573,
    "CANDIDATE ID": 1586,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 574,
    "CANDIDATE ID": 1588,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 575,
    "CANDIDATE ID": 1589,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 576,
    "CANDIDATE ID": 1590,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 577,
    "CANDIDATE ID": 1591,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 578,
    "CANDIDATE ID": 1592,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 579,
    "CANDIDATE ID": 1593,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 580,
    "CANDIDATE ID": 1595,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 581,
    "CANDIDATE ID": 1596,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 582,
    "CANDIDATE ID": 1597,
    R: 16,
    W: 30,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 583,
    "CANDIDATE ID": 1598,
    R: 21,
    W: 33,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 584,
    "CANDIDATE ID": 1599,
    R: 10,
    W: 45,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 585,
    "CANDIDATE ID": 1600,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 586,
    "CANDIDATE ID": 1601,
    R: 7,
    W: 33,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 587,
    "CANDIDATE ID": 1602,
    R: 15,
    W: 39,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 588,
    "CANDIDATE ID": 1603,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 589,
    "CANDIDATE ID": 1604,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 590,
    "CANDIDATE ID": 1605,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 591,
    "CANDIDATE ID": 1606,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 592,
    "CANDIDATE ID": 1607,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 593,
    "CANDIDATE ID": 1608,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 594,
    "CANDIDATE ID": 1609,
    R: 12,
    W: 36,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 595,
    "CANDIDATE ID": 1610,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 596,
    "CANDIDATE ID": 1611,
    R: 15,
    W: 12,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 597,
    "CANDIDATE ID": 1612,
    R: 8,
    W: 17,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 598,
    "CANDIDATE ID": 1613,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 599,
    "CANDIDATE ID": 1614,
    R: 8,
    W: 15,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 600,
    "CANDIDATE ID": 1615,
    R: 4,
    W: 23,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 601,
    "CANDIDATE ID": 1616,
    R: 7,
    W: 23,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 602,
    "CANDIDATE ID": 1617,
    R: 6,
    W: 15,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 603,
    "CANDIDATE ID": 1618,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 604,
    "CANDIDATE ID": 1619,
    R: 8,
    W: 34,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 605,
    "CANDIDATE ID": 1620,
    R: 5,
    W: 24,
    Total: -4,
    Percentage: null,
  },
  {
    "Sl no": 606,
    "CANDIDATE ID": 1621,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 607,
    "CANDIDATE ID": 1622,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 608,
    "CANDIDATE ID": 1623,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 609,
    "CANDIDATE ID": 1623,
    R: 2,
    W: 18,
    Total: -10,
    Percentage: -4.166666666666666,
  },
  {
    "Sl no": 610,
    "CANDIDATE ID": 1624,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 611,
    "CANDIDATE ID": 1625,
    R: 17,
    W: 13,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 612,
    "CANDIDATE ID": 1626,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 613,
    "CANDIDATE ID": 1627,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 614,
    "CANDIDATE ID": 1629,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 615,
    "CANDIDATE ID": 1630,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 616,
    "CANDIDATE ID": 1631,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 617,
    "CANDIDATE ID": 1632,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 618,
    "CANDIDATE ID": 1633,
    R: 18,
    W: 38,
    Total: 34,
    Percentage: 14.166666666666666,
  },
  {
    "Sl no": 619,
    "CANDIDATE ID": 1634,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 620,
    "CANDIDATE ID": 1635,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: -4.166666666666666,
  },
  {
    "Sl no": 621,
    "CANDIDATE ID": 1636,
    R: 12,
    W: 15,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 622,
    "CANDIDATE ID": 1637,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 623,
    "CANDIDATE ID": 1638,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583333333333333,
  },
  {
    "Sl no": 624,
    "CANDIDATE ID": 1639,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 625,
    "CANDIDATE ID": 1640,
    R: 15,
    W: 40,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 626,
    "CANDIDATE ID": 1641,
    R: 11,
    W: 35,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 627,
    "CANDIDATE ID": 1642,
    R: 9,
    W: 43,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 628,
    "CANDIDATE ID": 1643,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 629,
    "CANDIDATE ID": 1644,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 630,
    "CANDIDATE ID": 1645,
    R: 22,
    W: 31,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 631,
    "CANDIDATE ID": 1646,
    R: 8,
    W: 11,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 632,
    "CANDIDATE ID": 1647,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 633,
    "CANDIDATE ID": 1648,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 634,
    "CANDIDATE ID": 1651,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 635,
    "CANDIDATE ID": 1652,
    R: 25,
    W: 17,
    Total: 83,
    Percentage: 34.583,
  },
  {
    "Sl no": 636,
    "CANDIDATE ID": 1653,
    R: 13,
    W: 45,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 637,
    "CANDIDATE ID": 1654,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 638,
    "CANDIDATE ID": 1655,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 639,
    "CANDIDATE ID": 1656,
    R: 10,
    W: 49,
    Total: -9,
    Percentage: null,
  },
  {
    "Sl no": 640,
    "CANDIDATE ID": 1657,
    R: 11,
    W: 48,
    Total: -4,
    Percentage: null,
  },
  {
    "Sl no": 641,
    "CANDIDATE ID": 1658,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 642,
    "CANDIDATE ID": 1659,
    R: 9,
    W: 47,
    Total: -11,
    Percentage: null,
  },
  {
    "Sl no": 643,
    "CANDIDATE ID": 1660,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 644,
    "CANDIDATE ID": 1661,
    R: 17,
    W: 33,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 645,
    "CANDIDATE ID": 1662,
    R: 10,
    W: 37,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 646,
    "CANDIDATE ID": 1663,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 647,
    "CANDIDATE ID": 1664,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 648,
    "CANDIDATE ID": 1665,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 649,
    "CANDIDATE ID": 1666,
    R: 11,
    W: 38,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 650,
    "CANDIDATE ID": 1668,
    R: 16,
    W: 26,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 651,
    "CANDIDATE ID": 1669,
    R: 11,
    W: 30,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 652,
    "CANDIDATE ID": 1670,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 653,
    "CANDIDATE ID": 1671,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 654,
    "CANDIDATE ID": 1672,
    R: 16,
    W: 14,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 655,
    "CANDIDATE ID": 1673,
    R: 16,
    W: 23,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 656,
    "CANDIDATE ID": 1674,
    R: 22,
    W: 24,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 657,
    "CANDIDATE ID": 1676,
    R: 16,
    W: 36,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 658,
    "CANDIDATE ID": 1677,
    R: 13,
    W: 38,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 659,
    "CANDIDATE ID": 1678,
    R: 26,
    W: 14,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 660,
    "CANDIDATE ID": 1679,
    R: 26,
    W: 14,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 661,
    "CANDIDATE ID": 1680,
    R: 18,
    W: 14,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 662,
    "CANDIDATE ID": 1681,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 663,
    "CANDIDATE ID": 1682,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 664,
    "CANDIDATE ID": 1683,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 665,
    "CANDIDATE ID": 1684,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 666,
    "CANDIDATE ID": 1685,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 667,
    "CANDIDATE ID": 1686,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 668,
    "CANDIDATE ID": 1687,
    R: 16,
    W: 29,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 669,
    "CANDIDATE ID": 1688,
    R: 27,
    W: 6,
    Total: 102,
    Percentage: 42.5,
  },
  {
    "Sl no": 670,
    "CANDIDATE ID": 1688,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 671,
    "CANDIDATE ID": 1689,
    R: 14,
    W: 29,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 672,
    "CANDIDATE ID": 1690,
    R: 17,
    W: 28,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 673,
    "CANDIDATE ID": 1691,
    R: 19,
    W: 29,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 674,
    "CANDIDATE ID": 1692,
    R: 12,
    W: 28,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 675,
    "CANDIDATE ID": 1693,
    R: 16,
    W: 19,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 676,
    "CANDIDATE ID": 1694,
    R: 11,
    W: 38,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 677,
    "CANDIDATE ID": 1695,
    R: 14,
    W: 34,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 678,
    "CANDIDATE ID": 1696,
    R: 15,
    W: 27,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 679,
    "CANDIDATE ID": 1697,
    R: 20,
    W: 28,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 680,
    "CANDIDATE ID": 1698,
    R: 17,
    W: 31,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 681,
    "CANDIDATE ID": 1699,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 682,
    "CANDIDATE ID": 1700,
    R: 26,
    W: 4,
    Total: 100,
    Percentage: 41.66666666666667,
  },
  {
    "Sl no": 683,
    "CANDIDATE ID": 1700,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 684,
    "CANDIDATE ID": 1701,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 685,
    "CANDIDATE ID": 1702,
    R: 36,
    W: 20,
    Total: 124,
    Percentage: 51.667,
  },
  {
    "Sl no": 686,
    "CANDIDATE ID": 1703,
    R: 33,
    W: 21,
    Total: 111,
    Percentage: 46.25,
  },
  {
    "Sl no": 687,
    "CANDIDATE ID": 1704,
    R: 30,
    W: 20,
    Total: 100,
    Percentage: 41.667,
  },
  {
    "Sl no": 688,
    "CANDIDATE ID": 1705,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 689,
    "CANDIDATE ID": 1706,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 690,
    "CANDIDATE ID": 1707,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 691,
    "CANDIDATE ID": 1708,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 692,
    "CANDIDATE ID": 1709,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 693,
    "CANDIDATE ID": 1710,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 694,
    "CANDIDATE ID": 1711,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 695,
    "CANDIDATE ID": 1712,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 696,
    "CANDIDATE ID": 1713,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 697,
    "CANDIDATE ID": 1714,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 698,
    "CANDIDATE ID": 1715,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 699,
    "CANDIDATE ID": 1716,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 700,
    "CANDIDATE ID": 1718,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 701,
    "CANDIDATE ID": 1718,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 702,
    "CANDIDATE ID": 1719,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 703,
    "CANDIDATE ID": 1720,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 704,
    "CANDIDATE ID": 1721,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 705,
    "CANDIDATE ID": 1722,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 706,
    "CANDIDATE ID": 1723,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 707,
    "CANDIDATE ID": 1724,
    R: 8,
    W: 52,
    Total: -20,
    Percentage: null,
  },
  {
    "Sl no": 708,
    "CANDIDATE ID": 1725,
    R: 9,
    W: 23,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 709,
    "CANDIDATE ID": 1726,
    R: 11,
    W: 21,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 710,
    "CANDIDATE ID": 1727,
    R: 21,
    W: 24,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 711,
    "CANDIDATE ID": 1728,
    R: 11,
    W: 24,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 712,
    "CANDIDATE ID": 1729,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 713,
    "CANDIDATE ID": 1730,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 714,
    "CANDIDATE ID": 1731,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 715,
    "CANDIDATE ID": 1732,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 716,
    "CANDIDATE ID": 1733,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 717,
    "CANDIDATE ID": 1734,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 718,
    "CANDIDATE ID": 1735,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 719,
    "CANDIDATE ID": 1736,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 720,
    "CANDIDATE ID": 1737,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 721,
    "CANDIDATE ID": 1738,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 722,
    "CANDIDATE ID": 1739,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 723,
    "CANDIDATE ID": 1740,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 724,
    "CANDIDATE ID": 1741,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 725,
    "CANDIDATE ID": 1742,
    R: 22,
    W: 36,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 726,
    "CANDIDATE ID": 1743,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 727,
    "CANDIDATE ID": 1744,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 728,
    "CANDIDATE ID": 1745,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 729,
    "CANDIDATE ID": 1746,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 730,
    "CANDIDATE ID": 1747,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 731,
    "CANDIDATE ID": 1748,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 732,
    "CANDIDATE ID": 1749,
    R: 20,
    W: 36,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 733,
    "CANDIDATE ID": 1750,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 734,
    "CANDIDATE ID": 1750,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 735,
    "CANDIDATE ID": 1751,
    R: 15,
    W: 28,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 736,
    "CANDIDATE ID": 1752,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 737,
    "CANDIDATE ID": 1753,
    R: 14,
    W: 20,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 738,
    "CANDIDATE ID": 1754,
    R: 23,
    W: 34,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 739,
    "CANDIDATE ID": 1755,
    R: 17,
    W: 28,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 740,
    "CANDIDATE ID": 1756,
    R: 2,
    W: 7,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 741,
    "CANDIDATE ID": 1757,
    R: 8,
    W: 31,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 742,
    "CANDIDATE ID": 1758,
    R: 7,
    W: 19,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 743,
    "CANDIDATE ID": 1759,
    R: 17,
    W: 39,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 744,
    "CANDIDATE ID": 1760,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 745,
    "CANDIDATE ID": 1761,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833333333333334,
  },
  {
    "Sl no": 746,
    "CANDIDATE ID": 1762,
    R: 9,
    W: 15,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 747,
    "CANDIDATE ID": 1763,
    R: 14,
    W: 33,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 748,
    "CANDIDATE ID": 1764,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 749,
    "CANDIDATE ID": 1766,
    R: 9,
    W: 27,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 750,
    "CANDIDATE ID": 1767,
    R: 14,
    W: 40,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 751,
    "CANDIDATE ID": 1768,
    R: 14,
    W: 37,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 752,
    "CANDIDATE ID": 1769,
    R: 2,
    W: 8,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 753,
    "CANDIDATE ID": 1770,
    R: 13,
    W: 35,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 754,
    "CANDIDATE ID": 1771,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 755,
    "CANDIDATE ID": 1777,
    R: 18,
    W: 36,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 756,
    "CANDIDATE ID": 1778,
    R: 9,
    W: 26,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 757,
    "CANDIDATE ID": 1779,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 758,
    "CANDIDATE ID": 1780,
    R: 15,
    W: 41,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 759,
    "CANDIDATE ID": 1781,
    R: 3,
    W: 17,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 760,
    "CANDIDATE ID": 1782,
    R: 10,
    W: 11,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 761,
    "CANDIDATE ID": 1783,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 762,
    "CANDIDATE ID": 1784,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 763,
    "CANDIDATE ID": 1785,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 764,
    "CANDIDATE ID": 1801,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 765,
    "CANDIDATE ID": 1802,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 766,
    "CANDIDATE ID": 1803,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 767,
    "CANDIDATE ID": 1804,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 768,
    "CANDIDATE ID": 1805,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 769,
    "CANDIDATE ID": 1806,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 770,
    "CANDIDATE ID": 1807,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 771,
    "CANDIDATE ID": 1808,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 772,
    "CANDIDATE ID": 1809,
    R: 10,
    W: 32,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 773,
    "CANDIDATE ID": 1810,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 774,
    "CANDIDATE ID": 1811,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 775,
    "CANDIDATE ID": 1812,
    R: 14,
    W: 47,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 776,
    "CANDIDATE ID": 1813,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 777,
    "CANDIDATE ID": 1814,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 778,
    "CANDIDATE ID": 1815,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 779,
    "CANDIDATE ID": 1816,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 780,
    "CANDIDATE ID": 1817,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 781,
    "CANDIDATE ID": 1818,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 782,
    "CANDIDATE ID": 1819,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 783,
    "CANDIDATE ID": 1820,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 784,
    "CANDIDATE ID": 1821,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 785,
    "CANDIDATE ID": 1822,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 786,
    "CANDIDATE ID": 1823,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 787,
    "CANDIDATE ID": 1824,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 788,
    "CANDIDATE ID": 1825,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 789,
    "CANDIDATE ID": 1826,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 790,
    "CANDIDATE ID": 1827,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 791,
    "CANDIDATE ID": 1828,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 792,
    "CANDIDATE ID": 1829,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 793,
    "CANDIDATE ID": 1830,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 794,
    "CANDIDATE ID": 1831,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 795,
    "CANDIDATE ID": 1832,
    R: 16,
    W: 39,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 796,
    "CANDIDATE ID": 1833,
    R: 10,
    W: 49,
    Total: -9,
    Percentage: null,
  },
  {
    "Sl no": 797,
    "CANDIDATE ID": 1834,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 798,
    "CANDIDATE ID": 1835,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 799,
    "CANDIDATE ID": 1836,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 800,
    "CANDIDATE ID": 1837,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 801,
    "CANDIDATE ID": 1851,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 802,
    "CANDIDATE ID": 1852,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 803,
    "CANDIDATE ID": 1853,
    R: 17,
    W: 25,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 804,
    "CANDIDATE ID": 1854,
    R: 8,
    W: 26,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 805,
    "CANDIDATE ID": 1855,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 806,
    "CANDIDATE ID": 1856,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 807,
    "CANDIDATE ID": 1857,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 808,
    "CANDIDATE ID": 1858,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 809,
    "CANDIDATE ID": 1859,
    R: 17,
    W: 19,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 810,
    "CANDIDATE ID": 1860,
    R: 7,
    W: 17,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 811,
    "CANDIDATE ID": 1861,
    R: 8,
    W: 25,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 812,
    "CANDIDATE ID": 1862,
    R: 12,
    W: 33,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 813,
    "CANDIDATE ID": 1863,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 814,
    "CANDIDATE ID": 1864,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 815,
    "CANDIDATE ID": 1865,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 816,
    "CANDIDATE ID": 1866,
    R: 24,
    W: 35,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 817,
    "CANDIDATE ID": 1867,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 818,
    "CANDIDATE ID": 1868,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 819,
    "CANDIDATE ID": 1869,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 820,
    "CANDIDATE ID": 1870,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 821,
    "CANDIDATE ID": 1871,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 822,
    "CANDIDATE ID": 1872,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 823,
    "CANDIDATE ID": 1873,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 824,
    "CANDIDATE ID": 1874,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 825,
    "CANDIDATE ID": 1875,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 826,
    "CANDIDATE ID": 1876,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 827,
    "CANDIDATE ID": 1877,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 828,
    "CANDIDATE ID": 1878,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 829,
    "CANDIDATE ID": 1879,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 830,
    "CANDIDATE ID": 1880,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 831,
    "CANDIDATE ID": 1901,
    R: 14,
    W: 37,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 832,
    "CANDIDATE ID": 1902,
    R: 10,
    W: 36,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 833,
    "CANDIDATE ID": 1903,
    R: 21,
    W: 29,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 834,
    "CANDIDATE ID": 1904,
    R: 13,
    W: 29,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 835,
    "CANDIDATE ID": 1905,
    R: 12,
    W: 28,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 836,
    "CANDIDATE ID": 1906,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 837,
    "CANDIDATE ID": 1907,
    R: 19,
    W: 33,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 838,
    "CANDIDATE ID": 1908,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 839,
    "CANDIDATE ID": 1909,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 840,
    "CANDIDATE ID": 1909,
    R: 8,
    W: 42,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 841,
    "CANDIDATE ID": 1910,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 842,
    "CANDIDATE ID": 1911,
    R: 13,
    W: 35,
    Total: 17,
    Percentage: 7.083333333333333,
  },
  {
    "Sl no": 843,
    "CANDIDATE ID": 1912,
    R: 9,
    W: 42,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 844,
    "CANDIDATE ID": 1913,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 845,
    "CANDIDATE ID": 1914,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 846,
    "CANDIDATE ID": 1915,
    R: 12,
    W: 38,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 847,
    "CANDIDATE ID": 1916,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 848,
    "CANDIDATE ID": 1917,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 849,
    "CANDIDATE ID": 1918,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 850,
    "CANDIDATE ID": 1919,
    R: 12,
    W: 40,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 851,
    "CANDIDATE ID": 1920,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 852,
    "CANDIDATE ID": 1921,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 853,
    "CANDIDATE ID": 1922,
    R: 11,
    W: 28,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 854,
    "CANDIDATE ID": 1923,
    R: 14,
    W: 39,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 855,
    "CANDIDATE ID": 1924,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 856,
    "CANDIDATE ID": 1925,
    R: 11,
    W: 20,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 857,
    "CANDIDATE ID": 1926,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 858,
    "CANDIDATE ID": 1927,
    R: 12,
    W: 42,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 859,
    "CANDIDATE ID": 1928,
    R: 18,
    W: 29,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 860,
    "CANDIDATE ID": 1929,
    R: 13,
    W: 45,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 861,
    "CANDIDATE ID": 1930,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 862,
    "CANDIDATE ID": 1931,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 863,
    "CANDIDATE ID": 1932,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 864,
    "CANDIDATE ID": 1933,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 865,
    "CANDIDATE ID": 1934,
    R: 20,
    W: 33,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 866,
    "CANDIDATE ID": 1935,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 867,
    "CANDIDATE ID": 1936,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 868,
    "CANDIDATE ID": 1937,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 869,
    "CANDIDATE ID": 1938,
    R: 23,
    W: 31,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 870,
    "CANDIDATE ID": 1939,
    R: 16,
    W: 18,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 871,
    "CANDIDATE ID": 1940,
    R: 23,
    W: 36,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 872,
    "CANDIDATE ID": 1941,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 873,
    "CANDIDATE ID": 1942,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 874,
    "CANDIDATE ID": 1943,
    R: 18,
    W: 39,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 875,
    "CANDIDATE ID": 1944,
    R: 9,
    W: 39,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 876,
    "CANDIDATE ID": 1945,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 877,
    "CANDIDATE ID": 1946,
    R: 11,
    W: 35,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 878,
    "CANDIDATE ID": 1947,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 879,
    "CANDIDATE ID": 1948,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 880,
    "CANDIDATE ID": 1949,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 881,
    "CANDIDATE ID": 1950,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 882,
    "CANDIDATE ID": 1951,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 883,
    "CANDIDATE ID": 1952,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 884,
    "CANDIDATE ID": 1953,
    R: 18,
    W: 12,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 885,
    "CANDIDATE ID": 1954,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 886,
    "CANDIDATE ID": 1955,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 887,
    "CANDIDATE ID": 1956,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 888,
    "CANDIDATE ID": 1957,
    R: 5,
    W: 18,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 889,
    "CANDIDATE ID": 1958,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 890,
    "CANDIDATE ID": 1959,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 891,
    "CANDIDATE ID": 1960,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 892,
    "CANDIDATE ID": 1961,
    R: 12,
    W: 44,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 893,
    "CANDIDATE ID": 1962,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 894,
    "CANDIDATE ID": 1963,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 895,
    "CANDIDATE ID": 1964,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 896,
    "CANDIDATE ID": 1965,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 897,
    "CANDIDATE ID": 1966,
    R: 11,
    W: 14,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 898,
    "CANDIDATE ID": 1967,
    R: 15,
    W: 24,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 899,
    "CANDIDATE ID": 1968,
    R: 15,
    W: 35,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 900,
    "CANDIDATE ID": 1969,
    R: 14,
    W: 34,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 901,
    "CANDIDATE ID": 1970,
    R: 18,
    W: 33,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 902,
    "CANDIDATE ID": 1971,
    R: 10,
    W: 38,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 903,
    "CANDIDATE ID": 1972,
    R: 12,
    W: 23,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 904,
    "CANDIDATE ID": 1973,
    R: 8,
    W: 32,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 905,
    "CANDIDATE ID": 1974,
    R: 7,
    W: 16,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 906,
    "CANDIDATE ID": 1975,
    R: 14,
    W: 38,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 907,
    "CANDIDATE ID": 1976,
    R: 17,
    W: 39,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 908,
    "CANDIDATE ID": 1977,
    R: 17,
    W: 39,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 909,
    "CANDIDATE ID": 2001,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 910,
    "CANDIDATE ID": 2002,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 911,
    "CANDIDATE ID": 2003,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 912,
    "CANDIDATE ID": 2004,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 913,
    "CANDIDATE ID": 2005,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.666666666666664,
  },
  {
    "Sl no": 914,
    "CANDIDATE ID": 2006,
    R: 11,
    W: 24,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 915,
    "CANDIDATE ID": 2007,
    R: 11,
    W: 14,
    Total: 4,
    Percentage: 1.6666666666666667,
  },
  {
    "Sl no": 916,
    "CANDIDATE ID": 2008,
    R: 13,
    W: 35,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 917,
    "CANDIDATE ID": 2009,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 918,
    "CANDIDATE ID": 2010,
    R: 19,
    W: 33,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 919,
    "CANDIDATE ID": 2011,
    R: 9,
    W: 27,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 920,
    "CANDIDATE ID": 2012,
    R: 14,
    W: 32,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 921,
    "CANDIDATE ID": 2013,
    R: 15,
    W: 40,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 922,
    "CANDIDATE ID": 2014,
    R: 19,
    W: 37,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 923,
    "CANDIDATE ID": 2015,
    R: 10,
    W: 30,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 924,
    "CANDIDATE ID": 2016,
    R: 13,
    W: 20,
    Total: 32,
    Percentage: 13.333333333333334,
  },
  {
    "Sl no": 925,
    "CANDIDATE ID": 2017,
    R: 24,
    W: 9,
    Total: 87,
    Percentage: 36.25,
  },
  {
    "Sl no": 926,
    "CANDIDATE ID": 2017,
    R: 13,
    W: 12,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 927,
    "CANDIDATE ID": 2019,
    R: 12,
    W: 24,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 928,
    "CANDIDATE ID": 2020,
    R: 12,
    W: 41,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 929,
    "CANDIDATE ID": 2021,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 930,
    "CANDIDATE ID": 2022,
    R: 16,
    W: 31,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 931,
    "CANDIDATE ID": 2023,
    R: 13,
    W: 23,
    Total: 29,
    Percentage: 12.083333333333334,
  },
  {
    "Sl no": 932,
    "CANDIDATE ID": 2024,
    R: 9,
    W: 27,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 933,
    "CANDIDATE ID": 2025,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 934,
    "CANDIDATE ID": 2026,
    R: 7,
    W: 15,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 935,
    "CANDIDATE ID": 2027,
    R: 21,
    W: 27,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 936,
    "CANDIDATE ID": 2028,
    R: 21,
    W: 26,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 937,
    "CANDIDATE ID": 2029,
    R: 21,
    W: 20,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 938,
    "CANDIDATE ID": 2030,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 939,
    "CANDIDATE ID": 2031,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 940,
    "CANDIDATE ID": 2032,
    R: 17,
    W: 37,
    Total: 31,
    Percentage: 12.916666666666668,
  },
  {
    "Sl no": 941,
    "CANDIDATE ID": 2033,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 942,
    "CANDIDATE ID": 2034,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 943,
    "CANDIDATE ID": 2035,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 944,
    "CANDIDATE ID": 2036,
    R: 12,
    W: 38,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 945,
    "CANDIDATE ID": 2037,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 946,
    "CANDIDATE ID": 2038,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 947,
    "CANDIDATE ID": 2039,
    R: 18,
    W: 36,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 948,
    "CANDIDATE ID": 2040,
    R: 21,
    W: 37,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 949,
    "CANDIDATE ID": 2041,
    R: 13,
    W: 37,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 950,
    "CANDIDATE ID": 2042,
    R: 16,
    W: 37,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 951,
    "CANDIDATE ID": 2043,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 952,
    "CANDIDATE ID": 2044,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 953,
    "CANDIDATE ID": 2045,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 954,
    "CANDIDATE ID": 2046,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 955,
    "CANDIDATE ID": 2047,
    R: 16,
    W: 20,
    Total: 44,
    Percentage: 18.333333333333332,
  },
  {
    "Sl no": 956,
    "CANDIDATE ID": 2048,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 957,
    "CANDIDATE ID": 2049,
    R: 9,
    W: 25,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 958,
    "CANDIDATE ID": 2050,
    R: 13,
    W: 27,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 959,
    "CANDIDATE ID": 2051,
    R: 14,
    W: 41,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 960,
    "CANDIDATE ID": 2052,
    R: 17,
    W: 35,
    Total: 33,
    Percentage: 13.750000000000002,
  },
  {
    "Sl no": 961,
    "CANDIDATE ID": 2053,
    R: 40,
    W: 20,
    Total: 140,
    Percentage: 58.333,
  },
  {
    "Sl no": 962,
    "CANDIDATE ID": 2054,
    R: 38,
    W: 22,
    Total: 130,
    Percentage: 54.167,
  },
  {
    "Sl no": 963,
    "CANDIDATE ID": 2055,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 964,
    "CANDIDATE ID": 2056,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 965,
    "CANDIDATE ID": 2057,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 966,
    "CANDIDATE ID": 2058,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 967,
    "CANDIDATE ID": 2059,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 968,
    "CANDIDATE ID": 2060,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 969,
    "CANDIDATE ID": 2061,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 970,
    "CANDIDATE ID": 2062,
    R: 12,
    W: 45,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 971,
    "CANDIDATE ID": 2063,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.666666666666664,
  },
  {
    "Sl no": 972,
    "CANDIDATE ID": 2064,
    R: 15,
    W: 35,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 973,
    "CANDIDATE ID": 2065,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 974,
    "CANDIDATE ID": 2066,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 975,
    "CANDIDATE ID": 2067,
    R: 16,
    W: 41,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 976,
    "CANDIDATE ID": 2068,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 977,
    "CANDIDATE ID": 2069,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 978,
    "CANDIDATE ID": 2070,
    R: 8,
    W: 19,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 979,
    "CANDIDATE ID": 2071,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 980,
    "CANDIDATE ID": 2072,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 981,
    "CANDIDATE ID": 2073,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 982,
    "CANDIDATE ID": 2074,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 983,
    "CANDIDATE ID": 2075,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 984,
    "CANDIDATE ID": 2076,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 985,
    "CANDIDATE ID": 2077,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 986,
    "CANDIDATE ID": 2078,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 987,
    "CANDIDATE ID": 2079,
    R: 11,
    W: 33,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 988,
    "CANDIDATE ID": 2080,
    R: 18,
    W: 43,
    Total: 29,
    Percentage: 12.083333333333334,
  },
  {
    "Sl no": 989,
    "CANDIDATE ID": 2081,
    R: 9,
    W: 49,
    Total: -13,
    Percentage: null,
  },
  {
    "Sl no": 990,
    "CANDIDATE ID": 2082,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 991,
    "CANDIDATE ID": 2083,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 992,
    "CANDIDATE ID": 2084,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 993,
    "CANDIDATE ID": 2085,
    R: 12,
    W: 45,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 994,
    "CANDIDATE ID": 2086,
    R: 15,
    W: 38,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 995,
    "CANDIDATE ID": 2087,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 996,
    "CANDIDATE ID": 2088,
    R: 12,
    W: 41,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 997,
    "CANDIDATE ID": 2089,
    R: 9,
    W: 17,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 998,
    "CANDIDATE ID": 2090,
    R: 9,
    W: 23,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 999,
    "CANDIDATE ID": 2091,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1000,
    "CANDIDATE ID": 2092,
    R: 14,
    W: 42,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1001,
    "CANDIDATE ID": 2093,
    R: 16,
    W: 20,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 1002,
    "CANDIDATE ID": 2094,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1003,
    "CANDIDATE ID": 2095,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1004,
    "CANDIDATE ID": 2096,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1005,
    "CANDIDATE ID": 2097,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1006,
    "CANDIDATE ID": 2098,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1007,
    "CANDIDATE ID": 2099,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1008,
    "CANDIDATE ID": 2100,
    R: 13,
    W: 45,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 1009,
    "CANDIDATE ID": 2101,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1010,
    "CANDIDATE ID": 2102,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1011,
    "CANDIDATE ID": 2103,
    R: 28,
    W: 27,
    Total: 85,
    Percentage: 35.417,
  },
  {
    "Sl no": 1012,
    "CANDIDATE ID": 2104,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1013,
    "CANDIDATE ID": 2105,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1014,
    "CANDIDATE ID": 2106,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1015,
    "CANDIDATE ID": 2107,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1016,
    "CANDIDATE ID": 2108,
    R: 5,
    W: 14,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1017,
    "CANDIDATE ID": 2109,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1018,
    "CANDIDATE ID": 2110,
    R: 17,
    W: 31,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1019,
    "CANDIDATE ID": 2111,
    R: 22,
    W: 31,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 1020,
    "CANDIDATE ID": 2112,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1021,
    "CANDIDATE ID": 2113,
    R: 12,
    W: 36,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1022,
    "CANDIDATE ID": 2114,
    R: 4,
    W: 21,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1023,
    "CANDIDATE ID": 2115,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1024,
    "CANDIDATE ID": 2116,
    R: 12,
    W: 14,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 1025,
    "CANDIDATE ID": 2117,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1026,
    "CANDIDATE ID": 2118,
    R: 20,
    W: 24,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1027,
    "CANDIDATE ID": 2119,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 1028,
    "CANDIDATE ID": 2120,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1029,
    "CANDIDATE ID": 2121,
    R: 12,
    W: 32,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1030,
    "CANDIDATE ID": 2122,
    R: 7,
    W: 31,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 1031,
    "CANDIDATE ID": 2123,
    R: 10,
    W: 12,
    Total: 28,
    Percentage: 11.666666666666666,
  },
  {
    "Sl no": 1032,
    "CANDIDATE ID": 2124,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.33333333333333,
  },
  {
    "Sl no": 1033,
    "CANDIDATE ID": 2125,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1034,
    "CANDIDATE ID": 2126,
    R: 15,
    W: 20,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1035,
    "CANDIDATE ID": 2127,
    R: 14,
    W: 41,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1036,
    "CANDIDATE ID": 2136,
    R: 16,
    W: 17,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1037,
    "CANDIDATE ID": 2137,
    R: 35,
    W: 25,
    Total: 115,
    Percentage: 47.917,
  },
  {
    "Sl no": 1038,
    "CANDIDATE ID": 2138,
    R: 18,
    W: 25,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1039,
    "CANDIDATE ID": 2139,
    R: 22,
    W: 34,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 1040,
    "CANDIDATE ID": 2140,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1041,
    "CANDIDATE ID": 2141,
    R: 21,
    W: 20,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 1042,
    "CANDIDATE ID": 2142,
    R: 17,
    W: 29,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 1043,
    "CANDIDATE ID": 2143,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1044,
    "CANDIDATE ID": 2144,
    R: 14,
    W: 20,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1045,
    "CANDIDATE ID": 2145,
    R: 15,
    W: 36,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1046,
    "CANDIDATE ID": 2146,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1047,
    "CANDIDATE ID": 2147,
    R: 19,
    W: 39,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1048,
    "CANDIDATE ID": 2148,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1049,
    "CANDIDATE ID": 2149,
    R: 2,
    W: 15,
    Total: -7,
    Percentage: -2.9166666666666665,
  },
  {
    "Sl no": 1050,
    "CANDIDATE ID": 2150,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1051,
    "CANDIDATE ID": 2151,
    R: 8,
    W: 30,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1052,
    "CANDIDATE ID": 2152,
    R: 8,
    W: 14,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 1053,
    "CANDIDATE ID": 2153,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1054,
    "CANDIDATE ID": 2154,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1055,
    "CANDIDATE ID": 2155,
    R: 6,
    W: 38,
    Total: -14,
    Percentage: null,
  },
  {
    "Sl no": 1056,
    "CANDIDATE ID": 2156,
    R: 10,
    W: 34,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1057,
    "CANDIDATE ID": 2157,
    R: 14,
    W: 19,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1058,
    "CANDIDATE ID": 2158,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1059,
    "CANDIDATE ID": 2159,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1060,
    "CANDIDATE ID": 2160,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 1061,
    "CANDIDATE ID": 2161,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1062,
    "CANDIDATE ID": 2162,
    R: 14,
    W: 37,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1063,
    "CANDIDATE ID": 2165,
    R: 13,
    W: 26,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1064,
    "CANDIDATE ID": 2166,
    R: 12,
    W: 31,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 1065,
    "CANDIDATE ID": 2167,
    R: 13,
    W: 37,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1066,
    "CANDIDATE ID": 2168,
    R: 11,
    W: 38,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1067,
    "CANDIDATE ID": 2169,
    R: 16,
    W: 27,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1068,
    "CANDIDATE ID": 2170,
    R: 6,
    W: 24,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1069,
    "CANDIDATE ID": 2171,
    R: 13,
    W: 34,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 1070,
    "CANDIDATE ID": 2172,
    R: 11,
    W: 28,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1071,
    "CANDIDATE ID": 2173,
    R: 12,
    W: 37,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1072,
    "CANDIDATE ID": 2174,
    R: 15,
    W: 38,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1073,
    "CANDIDATE ID": 2175,
    R: 9,
    W: 22,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1074,
    "CANDIDATE ID": 2176,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1075,
    "CANDIDATE ID": 2177,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1076,
    "CANDIDATE ID": 2178,
    R: 12,
    W: 33,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1077,
    "CANDIDATE ID": 2179,
    R: 11,
    W: 40,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1078,
    "CANDIDATE ID": 2180,
    R: 16,
    W: 36,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1079,
    "CANDIDATE ID": 2181,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1080,
    "CANDIDATE ID": 2182,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1081,
    "CANDIDATE ID": 2183,
    R: 10,
    W: 31,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1082,
    "CANDIDATE ID": 2184,
    R: 8,
    W: 34,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 1083,
    "CANDIDATE ID": 2185,
    R: 20,
    W: 35,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1084,
    "CANDIDATE ID": 2186,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1085,
    "CANDIDATE ID": 2187,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1086,
    "CANDIDATE ID": 2188,
    R: 26,
    W: 15,
    Total: 89,
    Percentage: 37.083,
  },
  {
    "Sl no": 1087,
    "CANDIDATE ID": 2189,
    R: 9,
    W: 15,
    Total: -14,
    Percentage: -5.833333333333333,
  },
  {
    "Sl no": 1088,
    "CANDIDATE ID": 2190,
    R: 13,
    W: 29,
    Total: 23,
    Percentage: 9.583333333333334,
  },
  {
    "Sl no": 1089,
    "CANDIDATE ID": 2191,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1090,
    "CANDIDATE ID": 2192,
    R: 11,
    W: 48,
    Total: -2,
    Percentage: -0.8333333333333334,
  },
  {
    "Sl no": 1091,
    "CANDIDATE ID": 2193,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1092,
    "CANDIDATE ID": 2194,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1093,
    "CANDIDATE ID": 2195,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1094,
    "CANDIDATE ID": 2196,
    R: 22,
    W: 34,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 1095,
    "CANDIDATE ID": 2197,
    R: 26,
    W: 32,
    Total: 72,
    Percentage: 30,
  },
  {
    "Sl no": 1096,
    "CANDIDATE ID": 2198,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1097,
    "CANDIDATE ID": 2199,
    R: 22,
    W: 37,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1098,
    "CANDIDATE ID": 2200,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 1099,
    "CANDIDATE ID": 2201,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1100,
    "CANDIDATE ID": 2202,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1101,
    "CANDIDATE ID": 2203,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1102,
    "CANDIDATE ID": 2204,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1103,
    "CANDIDATE ID": 2205,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1104,
    "CANDIDATE ID": 2206,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1105,
    "CANDIDATE ID": 2207,
    R: 15,
    W: 27,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 1106,
    "CANDIDATE ID": 2208,
    R: 22,
    W: 23,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 1107,
    "CANDIDATE ID": 2209,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1108,
    "CANDIDATE ID": 2210,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1109,
    "CANDIDATE ID": 2211,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1110,
    "CANDIDATE ID": 2212,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1111,
    "CANDIDATE ID": 2213,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1112,
    "CANDIDATE ID": 2214,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1113,
    "CANDIDATE ID": 2215,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1114,
    "CANDIDATE ID": 2217,
    R: 18,
    W: 35,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1115,
    "CANDIDATE ID": 2218,
    R: 19,
    W: 27,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 1116,
    "CANDIDATE ID": 2219,
    R: 19,
    W: 23,
    Total: 53,
    Percentage: 22.083,
  },
  {
    "Sl no": 1117,
    "CANDIDATE ID": 2220,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1118,
    "CANDIDATE ID": 2221,
    R: 19,
    W: 30,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1119,
    "CANDIDATE ID": 2222,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1120,
    "CANDIDATE ID": 2223,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1121,
    "CANDIDATE ID": 2224,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1122,
    "CANDIDATE ID": 2225,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1123,
    "CANDIDATE ID": 2226,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1124,
    "CANDIDATE ID": 2226,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1125,
    "CANDIDATE ID": 2227,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1126,
    "CANDIDATE ID": 2251,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1127,
    "CANDIDATE ID": 2252,
    R: 12,
    W: 43,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1128,
    "CANDIDATE ID": 2253,
    R: 11,
    W: 48,
    Total: -4,
    Percentage: null,
  },
  {
    "Sl no": 1129,
    "CANDIDATE ID": 2254,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 1130,
    "CANDIDATE ID": 2255,
    R: 19,
    W: 30,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1131,
    "CANDIDATE ID": 2256,
    R: 20,
    W: 30,
    Total: 50,
    Percentage: 20.833333333333336,
  },
  {
    "Sl no": 1132,
    "CANDIDATE ID": 2257,
    R: 8,
    W: 35,
    Total: -3,
    Percentage: -1.25,
  },
  {
    "Sl no": 1133,
    "CANDIDATE ID": 2258,
    R: 19,
    W: 39,
    Total: 37,
    Percentage: 15.416666666666668,
  },
  {
    "Sl no": 1134,
    "CANDIDATE ID": 2259,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1135,
    "CANDIDATE ID": 2260,
    R: 11,
    W: 37,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 1136,
    "CANDIDATE ID": 2261,
    R: 7,
    W: 51,
    Total: -23,
    Percentage: null,
  },
  {
    "Sl no": 1137,
    "CANDIDATE ID": 2262,
    R: 10,
    W: 15,
    Total: -10,
    Percentage: -4.166666666666666,
  },
  {
    "Sl no": 1138,
    "CANDIDATE ID": 2263,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1139,
    "CANDIDATE ID": 2265,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1140,
    "CANDIDATE ID": 2266,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1141,
    "CANDIDATE ID": 2267,
    R: 23,
    W: 34,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 1142,
    "CANDIDATE ID": 2268,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1143,
    "CANDIDATE ID": 2269,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 1144,
    "CANDIDATE ID": 2270,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1145,
    "CANDIDATE ID": 2271,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1146,
    "CANDIDATE ID": 2283,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1147,
    "CANDIDATE ID": 2284,
    R: 8,
    W: 41,
    Total: -9,
    Percentage: null,
  },
  {
    "Sl no": 1148,
    "CANDIDATE ID": 2285,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1149,
    "CANDIDATE ID": 2286,
    R: 16,
    W: 35,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 1150,
    "CANDIDATE ID": 2287,
    R: 6,
    W: 54,
    Total: -30,
    Percentage: null,
  },
  {
    "Sl no": 1151,
    "CANDIDATE ID": 2288,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.8333333333333334,
  },
  {
    "Sl no": 1152,
    "CANDIDATE ID": 2289,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1153,
    "CANDIDATE ID": 2290,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1154,
    "CANDIDATE ID": 2291,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583333333333333,
  },
  {
    "Sl no": 1155,
    "CANDIDATE ID": 2292,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1156,
    "CANDIDATE ID": 2293,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1157,
    "CANDIDATE ID": 2293,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1158,
    "CANDIDATE ID": 2294,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1159,
    "CANDIDATE ID": 2295,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1160,
    "CANDIDATE ID": 2297,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833333333333334,
  },
  {
    "Sl no": 1161,
    "CANDIDATE ID": 2298,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1162,
    "CANDIDATE ID": 2299,
    R: 15,
    W: 32,
    Total: 28,
    Percentage: 11.666666666666666,
  },
  {
    "Sl no": 1163,
    "CANDIDATE ID": 2300,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1164,
    "CANDIDATE ID": 2301,
    R: 18,
    W: 29,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 1165,
    "CANDIDATE ID": 2302,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1166,
    "CANDIDATE ID": 2303,
    R: 19,
    W: 22,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 1167,
    "CANDIDATE ID": 2304,
    R: 25,
    W: 33,
    Total: 67,
    Percentage: 27.917,
  },
  {
    "Sl no": 1168,
    "CANDIDATE ID": 2305,
    R: 25,
    W: 20,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 1169,
    "CANDIDATE ID": 2306,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 1170,
    "CANDIDATE ID": 2330,
    R: 22,
    W: 20,
    Total: 68,
    Percentage: 28.333,
  },
  {
    "Sl no": 1171,
    "CANDIDATE ID": 2331,
    R: 22,
    W: 18,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 1172,
    "CANDIDATE ID": 2332,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1173,
    "CANDIDATE ID": 2332,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1174,
    "CANDIDATE ID": 2333,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1175,
    "CANDIDATE ID": 2334,
    R: 27,
    W: 20,
    Total: 88,
    Percentage: 36.667,
  },
  {
    "Sl no": 1176,
    "CANDIDATE ID": 2335,
    R: 11,
    W: 33,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1177,
    "CANDIDATE ID": 2336,
    R: 15,
    W: 39,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1178,
    "CANDIDATE ID": 2337,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1179,
    "CANDIDATE ID": 2338,
    R: 13,
    W: 43,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1180,
    "CANDIDATE ID": 2339,
    R: 12,
    W: 29,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1181,
    "CANDIDATE ID": 2340,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1182,
    "CANDIDATE ID": 2341,
    R: 8,
    W: 52,
    Total: -20,
    Percentage: null,
  },
  {
    "Sl no": 1183,
    "CANDIDATE ID": 2342,
    R: 8,
    W: 52,
    Total: -20,
    Percentage: null,
  },
  {
    "Sl no": 1184,
    "CANDIDATE ID": 2343,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1185,
    "CANDIDATE ID": 2344,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1186,
    "CANDIDATE ID": 2345,
    R: 14,
    W: 32,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1187,
    "CANDIDATE ID": 2346,
    R: 10,
    W: 30,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1188,
    "CANDIDATE ID": 2347,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1189,
    "CANDIDATE ID": 2348,
    R: 40,
    W: 14,
    Total: 146,
    Percentage: 60.833,
  },
  {
    "Sl no": 1190,
    "CANDIDATE ID": 2349,
    R: 42,
    W: 18,
    Total: 150,
    Percentage: 62.5,
  },
  {
    "Sl no": 1191,
    "CANDIDATE ID": 2350,
    R: 10,
    W: 29,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1192,
    "CANDIDATE ID": 2351,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1193,
    "CANDIDATE ID": 2352,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1194,
    "CANDIDATE ID": 2353,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1195,
    "CANDIDATE ID": 2354,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1196,
    "CANDIDATE ID": 2355,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1197,
    "CANDIDATE ID": 2356,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1198,
    "CANDIDATE ID": 2357,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 1199,
    "CANDIDATE ID": 2358,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1200,
    "CANDIDATE ID": 2361,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1201,
    "CANDIDATE ID": 2361,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1202,
    "CANDIDATE ID": 2362,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1203,
    "CANDIDATE ID": 2363,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1204,
    "CANDIDATE ID": 2364,
    R: 14,
    W: 31,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 1205,
    "CANDIDATE ID": 2365,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1206,
    "CANDIDATE ID": 2366,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1207,
    "CANDIDATE ID": 2367,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1208,
    "CANDIDATE ID": 2368,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1209,
    "CANDIDATE ID": 2369,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083333333333332,
  },
  {
    "Sl no": 1210,
    "CANDIDATE ID": 2372,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1211,
    "CANDIDATE ID": 2373,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1212,
    "CANDIDATE ID": 2373,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1213,
    "CANDIDATE ID": 2375,
    R: 20,
    W: 31,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 1214,
    "CANDIDATE ID": 2376,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1215,
    "CANDIDATE ID": 2377,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1216,
    "CANDIDATE ID": 2378,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1217,
    "CANDIDATE ID": 2379,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 1218,
    "CANDIDATE ID": 2380,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1219,
    "CANDIDATE ID": 2381,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1220,
    "CANDIDATE ID": 2383,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1221,
    "CANDIDATE ID": 2384,
    R: 14,
    W: 43,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 1222,
    "CANDIDATE ID": 2385,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1223,
    "CANDIDATE ID": 2386,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1224,
    "CANDIDATE ID": 2387,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1225,
    "CANDIDATE ID": 2389,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1226,
    "CANDIDATE ID": 2390,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.8333333333333334,
  },
  {
    "Sl no": 1227,
    "CANDIDATE ID": 2391,
    R: 13,
    W: 38,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1228,
    "CANDIDATE ID": 2392,
    R: 10,
    W: 26,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1229,
    "CANDIDATE ID": 2393,
    R: 35,
    W: 25,
    Total: 115,
    Percentage: 47.91666666666667,
  },
  {
    "Sl no": 1230,
    "CANDIDATE ID": 2394,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1231,
    "CANDIDATE ID": 2395,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1232,
    "CANDIDATE ID": 2396,
    R: 18,
    W: 39,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 1233,
    "CANDIDATE ID": 2397,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1234,
    "CANDIDATE ID": 2398,
    R: 12,
    W: 28,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1235,
    "CANDIDATE ID": 2398,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1236,
    "CANDIDATE ID": 2399,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1237,
    "CANDIDATE ID": 2400,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1238,
    "CANDIDATE ID": 2410,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1239,
    "CANDIDATE ID": 2411,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083333333333333,
  },
  {
    "Sl no": 1240,
    "CANDIDATE ID": 2412,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1241,
    "CANDIDATE ID": 2413,
    R: 10,
    W: 24,
    Total: 16,
    Percentage: 6.666666666666667,
  },
  {
    "Sl no": 1242,
    "CANDIDATE ID": 2414,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1243,
    "CANDIDATE ID": 2415,
    R: 19,
    W: 33,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 1244,
    "CANDIDATE ID": 2416,
    R: 21,
    W: 32,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 1245,
    "CANDIDATE ID": 2417,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 1246,
    "CANDIDATE ID": 2418,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1247,
    "CANDIDATE ID": 2420,
    R: 18,
    W: 26,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1248,
    "CANDIDATE ID": 2421,
    R: 21,
    W: 26,
    Total: 58,
    Percentage: 24.167,
  },
  {
    "Sl no": 1249,
    "CANDIDATE ID": 2422,
    R: 21,
    W: 28,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1250,
    "CANDIDATE ID": 2423,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1251,
    "CANDIDATE ID": 2424,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1252,
    "CANDIDATE ID": 2425,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1253,
    "CANDIDATE ID": 2426,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1254,
    "CANDIDATE ID": 2427,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1255,
    "CANDIDATE ID": 2428,
    R: 16,
    W: 31,
    Total: 33,
    Percentage: 13.75,
  },
  {
    "Sl no": 1256,
    "CANDIDATE ID": 2429,
    R: 15,
    W: 41,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1257,
    "CANDIDATE ID": 2430,
    R: 25,
    W: 17,
    Total: 83,
    Percentage: 34.583,
  },
  {
    "Sl no": 1258,
    "CANDIDATE ID": 2430,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1259,
    "CANDIDATE ID": 2437,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1260,
    "CANDIDATE ID": 2438,
    R: 16,
    W: 24,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1261,
    "CANDIDATE ID": 2439,
    R: 8,
    W: 20,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1262,
    "CANDIDATE ID": 2440,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1263,
    "CANDIDATE ID": 2441,
    R: 10,
    W: 26,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1264,
    "CANDIDATE ID": 2442,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1265,
    "CANDIDATE ID": 2443,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1266,
    "CANDIDATE ID": 2444,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1267,
    "CANDIDATE ID": 2444,
    R: 2,
    W: 1,
    Total: 7,
    Percentage: 2.9166666666666665,
  },
  {
    "Sl no": 1268,
    "CANDIDATE ID": 2445,
    R: 15,
    W: 41,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1269,
    "CANDIDATE ID": 2446,
    R: 13,
    W: 26,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1270,
    "CANDIDATE ID": 2447,
    R: 11,
    W: 25,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1271,
    "CANDIDATE ID": 2448,
    R: 10,
    W: 47,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 1272,
    "CANDIDATE ID": 2449,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1273,
    "CANDIDATE ID": 2449,
    R: 4,
    W: 23,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 1274,
    "CANDIDATE ID": 2450,
    R: 10,
    W: 25,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1275,
    "CANDIDATE ID": 2451,
    R: 9,
    W: 20,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1276,
    "CANDIDATE ID": 2452,
    R: 20,
    W: 24,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1277,
    "CANDIDATE ID": 2453,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1278,
    "CANDIDATE ID": 2454,
    R: 11,
    W: 19,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1279,
    "CANDIDATE ID": 2455,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1280,
    "CANDIDATE ID": 2456,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1281,
    "CANDIDATE ID": 2457,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1282,
    "CANDIDATE ID": 2458,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1283,
    "CANDIDATE ID": 2459,
    R: 23,
    W: 17,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 1284,
    "CANDIDATE ID": 2460,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1285,
    "CANDIDATE ID": 2461,
    R: 10,
    W: 49,
    Total: -9,
    Percentage: -3.75,
  },
  {
    "Sl no": 1286,
    "CANDIDATE ID": 2462,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1287,
    "CANDIDATE ID": 2463,
    R: 20,
    W: 23,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 1288,
    "CANDIDATE ID": 2464,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1289,
    "CANDIDATE ID": 2465,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1290,
    "CANDIDATE ID": 2466,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1291,
    "CANDIDATE ID": 2467,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.3333333333333335,
  },
  {
    "Sl no": 1292,
    "CANDIDATE ID": 2468,
    R: 10,
    W: 47,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 1293,
    "CANDIDATE ID": 2469,
    R: 12,
    W: 41,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 1294,
    "CANDIDATE ID": 2470,
    R: 12,
    W: 40,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 1295,
    "CANDIDATE ID": 2471,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1296,
    "CANDIDATE ID": 2472,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1297,
    "CANDIDATE ID": 2473,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 1298,
    "CANDIDATE ID": 2474,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1299,
    "CANDIDATE ID": 2475,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1300,
    "CANDIDATE ID": 2476,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1301,
    "CANDIDATE ID": 2477,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1302,
    "CANDIDATE ID": 2478,
    R: 10,
    W: 48,
    Total: -8,
    Percentage: null,
  },
  {
    "Sl no": 1303,
    "CANDIDATE ID": 2479,
    R: 19,
    W: 25,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1304,
    "CANDIDATE ID": 2482,
    R: 16,
    W: 22,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 1305,
    "CANDIDATE ID": 2483,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1306,
    "CANDIDATE ID": 2484,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1307,
    "CANDIDATE ID": 2485,
    R: 9,
    W: 42,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 1308,
    "CANDIDATE ID": 2486,
    R: 11,
    W: 49,
    Total: -3,
    Percentage: -1.25,
  },
  {
    "Sl no": 1309,
    "CANDIDATE ID": 2487,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1310,
    "CANDIDATE ID": 2488,
    R: 14,
    W: 41,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1311,
    "CANDIDATE ID": 2489,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1312,
    "CANDIDATE ID": 2490,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1313,
    "CANDIDATE ID": 2491,
    R: 24,
    W: 30,
    Total: 66,
    Percentage: 27.5,
  },
  {
    "Sl no": 1314,
    "CANDIDATE ID": 2492,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1315,
    "CANDIDATE ID": 2493,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1316,
    "CANDIDATE ID": 2494,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1317,
    "CANDIDATE ID": 2495,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1318,
    "CANDIDATE ID": 2496,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083333333333333,
  },
  {
    "Sl no": 1319,
    "CANDIDATE ID": 2497,
    R: 11,
    W: 31,
    Total: 13,
    Percentage: 5.416666666666667,
  },
  {
    "Sl no": 1320,
    "CANDIDATE ID": 2498,
    R: 12,
    W: 44,
    Total: 4,
    Percentage: 1.6666666666666667,
  },
  {
    "Sl no": 1321,
    "CANDIDATE ID": 2499,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.666666666666664,
  },
  {
    "Sl no": 1322,
    "CANDIDATE ID": 2500,
    R: 21,
    W: 29,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1323,
    "CANDIDATE ID": 2501,
    R: 24,
    W: 17,
    Total: 79,
    Percentage: 32.917,
  },
  {
    "Sl no": 1324,
    "CANDIDATE ID": 2502,
    R: 12,
    W: 32,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1325,
    "CANDIDATE ID": 2503,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1326,
    "CANDIDATE ID": 2504,
    R: 12,
    W: 36,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1327,
    "CANDIDATE ID": 2505,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1328,
    "CANDIDATE ID": 2506,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1329,
    "CANDIDATE ID": 2507,
    R: 9,
    W: 38,
    Total: -2,
    Percentage: null,
  },
  {
    "Sl no": 1330,
    "CANDIDATE ID": 2508,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1331,
    "CANDIDATE ID": 2509,
    R: 6,
    W: 18,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1332,
    "CANDIDATE ID": 2510,
    R: 16,
    W: 38,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1333,
    "CANDIDATE ID": 2511,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.166666666666666,
  },
  {
    "Sl no": 1334,
    "CANDIDATE ID": 2511,
    R: 9,
    W: 30,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1335,
    "CANDIDATE ID": 2512,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1336,
    "CANDIDATE ID": 2513,
    R: 12,
    W: 24,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1337,
    "CANDIDATE ID": 2514,
    R: 10,
    W: 21,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1338,
    "CANDIDATE ID": 2515,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1339,
    "CANDIDATE ID": 2516,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1340,
    "CANDIDATE ID": 2517,
    R: 14,
    W: 30,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1341,
    "CANDIDATE ID": 2518,
    R: 12,
    W: 32,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1342,
    "CANDIDATE ID": 2519,
    R: 9,
    W: 44,
    Total: -8,
    Percentage: null,
  },
  {
    "Sl no": 1343,
    "CANDIDATE ID": 2520,
    R: 11,
    W: 42,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1344,
    "CANDIDATE ID": 2521,
    R: 11,
    W: 42,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1345,
    "CANDIDATE ID": 2522,
    R: 18,
    W: 25,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1346,
    "CANDIDATE ID": 2523,
    R: 26,
    W: 33,
    Total: 71,
    Percentage: 29.583,
  },
  {
    "Sl no": 1347,
    "CANDIDATE ID": 2524,
    R: 19,
    W: 39,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1348,
    "CANDIDATE ID": 2525,
    R: 24,
    W: 34,
    Total: 62,
    Percentage: 25.833,
  },
  {
    "Sl no": 1349,
    "CANDIDATE ID": 2526,
    R: 21,
    W: 33,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1350,
    "CANDIDATE ID": 2527,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1351,
    "CANDIDATE ID": 2528,
    R: 12,
    W: 34,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1352,
    "CANDIDATE ID": 2529,
    R: 22,
    W: 37,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1353,
    "CANDIDATE ID": 2530,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1354,
    "CANDIDATE ID": 2531,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1355,
    "CANDIDATE ID": 2532,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1356,
    "CANDIDATE ID": 2533,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1357,
    "CANDIDATE ID": 2534,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1358,
    "CANDIDATE ID": 2535,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 1359,
    "CANDIDATE ID": 2536,
    R: 10,
    W: 27,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 1360,
    "CANDIDATE ID": 2537,
    R: 11,
    W: 30,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1361,
    "CANDIDATE ID": 2538,
    R: 22,
    W: 37,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1362,
    "CANDIDATE ID": 2539,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1363,
    "CANDIDATE ID": 2540,
    R: 20,
    W: 34,
    Total: 46,
    Percentage: 19.166666666666668,
  },
  {
    "Sl no": 1364,
    "CANDIDATE ID": 2541,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1365,
    "CANDIDATE ID": 2542,
    R: 11,
    W: 42,
    Total: 2,
    Percentage: 0.8333333333333334,
  },
  {
    "Sl no": 1366,
    "CANDIDATE ID": 2543,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 1367,
    "CANDIDATE ID": 2544,
    R: 19,
    W: 29,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1368,
    "CANDIDATE ID": 2545,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1369,
    "CANDIDATE ID": 2546,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1370,
    "CANDIDATE ID": 2547,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1371,
    "CANDIDATE ID": 2548,
    R: 14,
    W: 32,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1372,
    "CANDIDATE ID": 2549,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1373,
    "CANDIDATE ID": 2550,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1374,
    "CANDIDATE ID": 2552,
    R: 7,
    W: 17,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1375,
    "CANDIDATE ID": 2553,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 1376,
    "CANDIDATE ID": 2554,
    R: 24,
    W: 30,
    Total: 66,
    Percentage: 27.5,
  },
  {
    "Sl no": 1377,
    "CANDIDATE ID": 2555,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1378,
    "CANDIDATE ID": 2556,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1379,
    "CANDIDATE ID": 2557,
    R: 12,
    W: 46,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1380,
    "CANDIDATE ID": 2558,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1381,
    "CANDIDATE ID": 2559,
    R: 17,
    W: 17,
    Total: 51,
    Percentage: 21.25,
  },
  {
    "Sl no": 1382,
    "CANDIDATE ID": 2560,
    R: 19,
    W: 22,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 1383,
    "CANDIDATE ID": 2561,
    R: 21,
    W: 28,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1384,
    "CANDIDATE ID": 2562,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 1385,
    "CANDIDATE ID": 2563,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1386,
    "CANDIDATE ID": 2564,
    R: 16,
    W: 22,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 1387,
    "CANDIDATE ID": 2566,
    R: 19,
    W: 35,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1388,
    "CANDIDATE ID": 2567,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1389,
    "CANDIDATE ID": 2568,
    R: 14,
    W: 28,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1390,
    "CANDIDATE ID": 2569,
    R: 9,
    W: 24,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1391,
    "CANDIDATE ID": 2570,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1392,
    "CANDIDATE ID": 2571,
    R: 21,
    W: 38,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1393,
    "CANDIDATE ID": 2573,
    R: 14,
    W: 15,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1394,
    "CANDIDATE ID": 2574,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1395,
    "CANDIDATE ID": 2575,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.4166666666666667,
  },
  {
    "Sl no": 1396,
    "CANDIDATE ID": 2576,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1397,
    "CANDIDATE ID": 2577,
    R: 19,
    W: 29,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1398,
    "CANDIDATE ID": 2578,
    R: 17,
    W: 22,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1399,
    "CANDIDATE ID": 2579,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1400,
    "CANDIDATE ID": 2580,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1401,
    "CANDIDATE ID": 2581,
    R: 18,
    W: 37,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1402,
    "CANDIDATE ID": 2582,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1403,
    "CANDIDATE ID": 2584,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1404,
    "CANDIDATE ID": 2585,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1405,
    "CANDIDATE ID": 2586,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1406,
    "CANDIDATE ID": 2587,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1407,
    "CANDIDATE ID": 2588,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1408,
    "CANDIDATE ID": 2589,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1409,
    "CANDIDATE ID": 2590,
    R: 22,
    W: 32,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1410,
    "CANDIDATE ID": 2591,
    R: 12,
    W: 44,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1411,
    "CANDIDATE ID": 2592,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1412,
    "CANDIDATE ID": 2593,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 1413,
    "CANDIDATE ID": 2594,
    R: 16,
    W: 41,
    Total: 23,
    Percentage: 9.583333333333334,
  },
  {
    "Sl no": 1414,
    "CANDIDATE ID": 2595,
    R: 24,
    W: 32,
    Total: 64,
    Percentage: 26.667,
  },
  {
    "Sl no": 1415,
    "CANDIDATE ID": 2596,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1416,
    "CANDIDATE ID": 2597,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1417,
    "CANDIDATE ID": 2598,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 1418,
    "CANDIDATE ID": 2599,
    R: 34,
    W: 42,
    Total: -30,
    Percentage: -12.5,
  },
  {
    "Sl no": 1419,
    "CANDIDATE ID": 2600,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1420,
    "CANDIDATE ID": 2601,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1421,
    "CANDIDATE ID": 2601,
    R: 4,
    W: 5,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1422,
    "CANDIDATE ID": 2602,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1423,
    "CANDIDATE ID": 2603,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1424,
    "CANDIDATE ID": 2604,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1425,
    "CANDIDATE ID": 2605,
    R: 11,
    W: 22,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1426,
    "CANDIDATE ID": 2606,
    R: 15,
    W: 32,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1427,
    "CANDIDATE ID": 2607,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1428,
    "CANDIDATE ID": 2608,
    R: 13,
    W: 36,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1429,
    "CANDIDATE ID": 2609,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1430,
    "CANDIDATE ID": 2610,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1431,
    "CANDIDATE ID": 2611,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1432,
    "CANDIDATE ID": 2612,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1433,
    "CANDIDATE ID": 2613,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1434,
    "CANDIDATE ID": 2614,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1435,
    "CANDIDATE ID": 2615,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1436,
    "CANDIDATE ID": 2616,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1437,
    "CANDIDATE ID": 2617,
    R: 23,
    W: 37,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1438,
    "CANDIDATE ID": 2618,
    R: 31,
    W: 29,
    Total: 95,
    Percentage: 39.583,
  },
  {
    "Sl no": 1439,
    "CANDIDATE ID": 2619,
    R: 18,
    W: 35,
    Total: 37,
    Percentage: 15.417,
  },
  {
    "Sl no": 1440,
    "CANDIDATE ID": 2620,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1441,
    "CANDIDATE ID": 2621,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1442,
    "CANDIDATE ID": 2622,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1443,
    "CANDIDATE ID": 2623,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1444,
    "CANDIDATE ID": 2624,
    R: 25,
    W: 35,
    Total: 65,
    Percentage: 27.083,
  },
  {
    "Sl no": 1445,
    "CANDIDATE ID": 2625,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 1446,
    "CANDIDATE ID": 2626,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1447,
    "CANDIDATE ID": 2627,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1448,
    "CANDIDATE ID": 2628,
    R: 7,
    W: 33,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1449,
    "CANDIDATE ID": 2629,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1450,
    "CANDIDATE ID": 2630,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1451,
    "CANDIDATE ID": 2631,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1452,
    "CANDIDATE ID": 2632,
    R: 15,
    W: 41,
    Total: 19,
    Percentage: 7.917,
  },
  {
    "Sl no": 1453,
    "CANDIDATE ID": 2633,
    R: 11,
    W: 32,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1454,
    "CANDIDATE ID": 2634,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 1455,
    "CANDIDATE ID": 2635,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1456,
    "CANDIDATE ID": 2636,
    R: 9,
    W: 48,
    Total: -12,
    Percentage: null,
  },
  {
    "Sl no": 1457,
    "CANDIDATE ID": 2676,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1458,
    "CANDIDATE ID": 2677,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1459,
    "CANDIDATE ID": 2678,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: -6.25,
  },
  {
    "Sl no": 1460,
    "CANDIDATE ID": 2679,
    R: 7,
    W: 43,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1461,
    "CANDIDATE ID": 2680,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1462,
    "CANDIDATE ID": 2681,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1463,
    "CANDIDATE ID": 2682,
    R: 19,
    W: 35,
    Total: 41,
    Percentage: 17.083333333333332,
  },
  {
    "Sl no": 1464,
    "CANDIDATE ID": 2683,
    R: 10,
    W: 48,
    Total: -8,
    Percentage: null,
  },
  {
    "Sl no": 1465,
    "CANDIDATE ID": 2684,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1466,
    "CANDIDATE ID": 2685,
    R: 12,
    W: 44,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1467,
    "CANDIDATE ID": 2686,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1468,
    "CANDIDATE ID": 2687,
    R: 15,
    W: 43,
    Total: 17,
    Percentage: 7.083,
  },
  {
    "Sl no": 1469,
    "CANDIDATE ID": 2688,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1470,
    "CANDIDATE ID": 2689,
    R: 7,
    W: 16,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1471,
    "CANDIDATE ID": 2690,
    R: 14,
    W: 38,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 1472,
    "CANDIDATE ID": 2691,
    R: 10,
    W: 12,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1473,
    "CANDIDATE ID": 2692,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1474,
    "CANDIDATE ID": 2693,
    R: 10,
    W: 41,
    Total: -1,
    Percentage: null,
  },
  {
    "Sl no": 1475,
    "CANDIDATE ID": 2694,
    R: 11,
    W: 49,
    Total: 5,
    Percentage: 6.667,
  },
  {
    "Sl no": 1476,
    "CANDIDATE ID": 2695,
    R: 13,
    W: 28,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1477,
    "CANDIDATE ID": 2696,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1478,
    "CANDIDATE ID": 2697,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1479,
    "CANDIDATE ID": 2698,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1480,
    "CANDIDATE ID": 2699,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1481,
    "CANDIDATE ID": 2700,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1482,
    "CANDIDATE ID": 2701,
    R: 10,
    W: 40,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1483,
    "CANDIDATE ID": 2702,
    R: 10,
    W: 35,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1484,
    "CANDIDATE ID": 2703,
    R: 11,
    W: 37,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 1485,
    "CANDIDATE ID": 2704,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 1486,
    "CANDIDATE ID": 2705,
    R: 11,
    W: 40,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1487,
    "CANDIDATE ID": 2706,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 1488,
    "CANDIDATE ID": 2707,
    R: 17,
    W: 41,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1489,
    "CANDIDATE ID": 2708,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1490,
    "CANDIDATE ID": 2709,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1491,
    "CANDIDATE ID": 2711,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1492,
    "CANDIDATE ID": 2712,
    R: 11,
    W: 41,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 1493,
    "CANDIDATE ID": 2713,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1494,
    "CANDIDATE ID": 2714,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1495,
    "CANDIDATE ID": 2715,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1496,
    "CANDIDATE ID": 2716,
    R: 14,
    W: 9,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1497,
    "CANDIDATE ID": 2717,
    R: 16,
    W: 21,
    Total: 43,
    Percentage: 17.917,
  },
  {
    "Sl no": 1498,
    "CANDIDATE ID": 2718,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1499,
    "CANDIDATE ID": 2719,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1500,
    "CANDIDATE ID": 2720,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1501,
    "CANDIDATE ID": 2721,
    R: 10,
    W: 49,
    Total: -9,
    Percentage: null,
  },
  {
    "Sl no": 1502,
    "CANDIDATE ID": 2722,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1503,
    "CANDIDATE ID": 2723,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1504,
    "CANDIDATE ID": 2724,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1505,
    "CANDIDATE ID": 2725,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1506,
    "CANDIDATE ID": 2726,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1507,
    "CANDIDATE ID": 2727,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1508,
    "CANDIDATE ID": 2728,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1509,
    "CANDIDATE ID": 2729,
    R: 14,
    W: 36,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1510,
    "CANDIDATE ID": 2730,
    R: 13,
    W: 38,
    Total: 14,
    Percentage: 5.833,
  },
  {
    "Sl no": 1511,
    "CANDIDATE ID": 2731,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1512,
    "CANDIDATE ID": 2732,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1513,
    "CANDIDATE ID": 2733,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.666666666666667,
  },
  {
    "Sl no": 1514,
    "CANDIDATE ID": 2734,
    R: 19,
    W: 38,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 1515,
    "CANDIDATE ID": 2735,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1516,
    "CANDIDATE ID": 2736,
    R: 18,
    W: 15,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 1517,
    "CANDIDATE ID": 2737,
    R: 16,
    W: 26,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 1518,
    "CANDIDATE ID": 2738,
    R: 18,
    W: 23,
    Total: 49,
    Percentage: 20.417,
  },
  {
    "Sl no": 1519,
    "CANDIDATE ID": 2739,
    R: 11,
    W: 18,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1520,
    "CANDIDATE ID": 2740,
    R: 20,
    W: 25,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1521,
    "CANDIDATE ID": 2741,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1522,
    "CANDIDATE ID": 2741,
    R: null,
    W: null,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1523,
    "CANDIDATE ID": 2742,
    R: 14,
    W: 30,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1524,
    "CANDIDATE ID": 2744,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: -4.166666666666666,
  },
  {
    "Sl no": 1525,
    "CANDIDATE ID": 2745,
    R: 7,
    W: 53,
    Total: -25,
    Percentage: null,
  },
  {
    "Sl no": 1526,
    "CANDIDATE ID": 2746,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1527,
    "CANDIDATE ID": 2747,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1528,
    "CANDIDATE ID": 2748,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1529,
    "CANDIDATE ID": 2749,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1530,
    "CANDIDATE ID": 2750,
    R: 10,
    W: 48,
    Total: -8,
    Percentage: -3.3333333333333335,
  },
  {
    "Sl no": 1531,
    "CANDIDATE ID": 2751,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1532,
    "CANDIDATE ID": 2752,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1533,
    "CANDIDATE ID": 2753,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1534,
    "CANDIDATE ID": 2754,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1535,
    "CANDIDATE ID": 2755,
    R: 9,
    W: 48,
    Total: -12,
    Percentage: null,
  },
  {
    "Sl no": 1536,
    "CANDIDATE ID": 2756,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1537,
    "CANDIDATE ID": 2757,
    R: 18,
    W: 40,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 1538,
    "CANDIDATE ID": 2760,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1539,
    "CANDIDATE ID": 2761,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1540,
    "CANDIDATE ID": 2762,
    R: 15,
    W: 40,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1541,
    "CANDIDATE ID": 2763,
    R: 20,
    W: 35,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1542,
    "CANDIDATE ID": 2764,
    R: 9,
    W: 26,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1543,
    "CANDIDATE ID": 2765,
    R: 16,
    W: 35,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 1544,
    "CANDIDATE ID": 2766,
    R: 11,
    W: 39,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1545,
    "CANDIDATE ID": 2767,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1546,
    "CANDIDATE ID": 2768,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1547,
    "CANDIDATE ID": 2769,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1548,
    "CANDIDATE ID": 2770,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1549,
    "CANDIDATE ID": 2771,
    R: 9,
    W: 25,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1550,
    "CANDIDATE ID": 2772,
    R: 14,
    W: 43,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 1551,
    "CANDIDATE ID": 2773,
    R: 17,
    W: 34,
    Total: 34,
    Percentage: 14.167,
  },
  {
    "Sl no": 1552,
    "CANDIDATE ID": 2774,
    R: 23,
    W: 31,
    Total: 61,
    Percentage: 25.417,
  },
  {
    "Sl no": 1553,
    "CANDIDATE ID": 2775,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1554,
    "CANDIDATE ID": 2776,
    R: 11,
    W: 42,
    Total: 2,
    Percentage: 0.833,
  },
  {
    "Sl no": 1555,
    "CANDIDATE ID": 2777,
    R: 16,
    W: 39,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1556,
    "CANDIDATE ID": 2778,
    R: 16,
    W: 37,
    Total: 27,
    Percentage: 11.25,
  },
  {
    "Sl no": 1557,
    "CANDIDATE ID": 2779,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1558,
    "CANDIDATE ID": 2780,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1559,
    "CANDIDATE ID": 2781,
    R: 12,
    W: 44,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1560,
    "CANDIDATE ID": 2782,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1561,
    "CANDIDATE ID": 2783,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1562,
    "CANDIDATE ID": 2784,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1563,
    "CANDIDATE ID": 2785,
    R: 14,
    W: 44,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1564,
    "CANDIDATE ID": 2786,
    R: 16,
    W: 34,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1565,
    "CANDIDATE ID": 2787,
    R: 16,
    W: 44,
    Total: 28,
    Percentage: 11.666666666666666,
  },
  {
    "Sl no": 1566,
    "CANDIDATE ID": 2788,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1567,
    "CANDIDATE ID": 2789,
    R: 8,
    W: 45,
    Total: -13,
    Percentage: null,
  },
  {
    "Sl no": 1568,
    "CANDIDATE ID": 2790,
    R: 14,
    W: 42,
    Total: 14,
    Percentage: 5.833333333333333,
  },
  {
    "Sl no": 1569,
    "CANDIDATE ID": 2791,
    R: 10,
    W: 50,
    Total: -10,
    Percentage: null,
  },
  {
    "Sl no": 1570,
    "CANDIDATE ID": 2792,
    R: 4,
    W: 46,
    Total: -30,
    Percentage: null,
  },
  {
    "Sl no": 1571,
    "CANDIDATE ID": 2793,
    R: 1,
    W: 38,
    Total: -34,
    Percentage: null,
  },
  {
    "Sl no": 1572,
    "CANDIDATE ID": 2794,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1573,
    "CANDIDATE ID": 2795,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1574,
    "CANDIDATE ID": 2796,
    R: 9,
    W: 51,
    Total: -15,
    Percentage: null,
  },
  {
    "Sl no": 1575,
    "CANDIDATE ID": 2797,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 1576,
    "CANDIDATE ID": 2798,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 1577,
    "CANDIDATE ID": 2799,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.166666666666666,
  },
  {
    "Sl no": 1578,
    "CANDIDATE ID": 2800,
    R: 8,
    W: 52,
    Total: -20,
    Percentage: -8.333333333333332,
  },
  {
    "Sl no": 1579,
    "CANDIDATE ID": 2801,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1580,
    "CANDIDATE ID": 2802,
    R: 9,
    W: 26,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1581,
    "CANDIDATE ID": 2803,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1582,
    "CANDIDATE ID": 2804,
    R: 8,
    W: 52,
    Total: -20,
    Percentage: -8.333333333333332,
  },
  {
    "Sl no": 1583,
    "CANDIDATE ID": 2805,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.4166666666666667,
  },
  {
    "Sl no": 1584,
    "CANDIDATE ID": 2806,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083333333333333,
  },
  {
    "Sl no": 1585,
    "CANDIDATE ID": 2807,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1586,
    "CANDIDATE ID": 2808,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1587,
    "CANDIDATE ID": 2809,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1588,
    "CANDIDATE ID": 2810,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1589,
    "CANDIDATE ID": 2811,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1590,
    "CANDIDATE ID": 2812,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1591,
    "CANDIDATE ID": 2813,
    R: 17,
    W: 30,
    Total: 38,
    Percentage: 15.833,
  },
  {
    "Sl no": 1592,
    "CANDIDATE ID": 2814,
    R: 22,
    W: 31,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 1593,
    "CANDIDATE ID": 2815,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1594,
    "CANDIDATE ID": 2816,
    R: 10,
    W: 46,
    Total: -6,
    Percentage: null,
  },
  {
    "Sl no": 1595,
    "CANDIDATE ID": 2817,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1596,
    "CANDIDATE ID": 2818,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1597,
    "CANDIDATE ID": 2819,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1598,
    "CANDIDATE ID": 2820,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1599,
    "CANDIDATE ID": 2821,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1600,
    "CANDIDATE ID": 2822,
    R: 19,
    W: 40,
    Total: 36,
    Percentage: 15,
  },
  {
    "Sl no": 1601,
    "CANDIDATE ID": 2823,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1602,
    "CANDIDATE ID": 2824,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1603,
    "CANDIDATE ID": 2825,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1604,
    "CANDIDATE ID": 2826,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1605,
    "CANDIDATE ID": 2827,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1606,
    "CANDIDATE ID": 2828,
    R: 15,
    W: 41,
    Total: 19,
    Percentage: 7.916666666666666,
  },
  {
    "Sl no": 1607,
    "CANDIDATE ID": 2830,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.166666666666668,
  },
  {
    "Sl no": 1608,
    "CANDIDATE ID": 2831,
    R: 26,
    W: 34,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 1609,
    "CANDIDATE ID": 2832,
    R: 18,
    W: 26,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1610,
    "CANDIDATE ID": 2833,
    R: 15,
    W: 21,
    Total: 39,
    Percentage: 16.25,
  },
  {
    "Sl no": 1611,
    "CANDIDATE ID": 2834,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1612,
    "CANDIDATE ID": 2835,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1613,
    "CANDIDATE ID": 2836,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1614,
    "CANDIDATE ID": 2837,
    R: 24,
    W: 34,
    Total: 62,
    Percentage: 25.833,
  },
  {
    "Sl no": 1615,
    "CANDIDATE ID": 2838,
    R: 20,
    W: 36,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 1616,
    "CANDIDATE ID": 2839,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1617,
    "CANDIDATE ID": 2840,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1618,
    "CANDIDATE ID": 2841,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1619,
    "CANDIDATE ID": 2842,
    R: 22,
    W: 8,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 1620,
    "CANDIDATE ID": 2843,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 1621,
    "CANDIDATE ID": 2844,
    R: 20,
    W: 10,
    Total: 70,
    Percentage: 29.167,
  },
  {
    "Sl no": 1622,
    "CANDIDATE ID": 2847,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1623,
    "CANDIDATE ID": 2848,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1624,
    "CANDIDATE ID": 2850,
    R: 11,
    W: 48,
    Total: -4,
    Percentage: -1.6666666666666667,
  },
  {
    "Sl no": 1625,
    "CANDIDATE ID": 2851,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1626,
    "CANDIDATE ID": 2852,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1627,
    "CANDIDATE ID": 2853,
    R: 12,
    W: 39,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1628,
    "CANDIDATE ID": 2854,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1629,
    "CANDIDATE ID": 2855,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1630,
    "CANDIDATE ID": 2856,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: -2.083333333333333,
  },
  {
    "Sl no": 1631,
    "CANDIDATE ID": 2857,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.667,
  },
  {
    "Sl no": 1632,
    "CANDIDATE ID": 2858,
    R: 20,
    W: 28,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 1633,
    "CANDIDATE ID": 2859,
    R: 16,
    W: 10,
    Total: 54,
    Percentage: 22.5,
  },
  {
    "Sl no": 1634,
    "CANDIDATE ID": 2860,
    R: 22,
    W: 11,
    Total: 77,
    Percentage: 32.083,
  },
  {
    "Sl no": 1635,
    "CANDIDATE ID": 2861,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1636,
    "CANDIDATE ID": 2862,
    R: 19,
    W: 35,
    Total: 41,
    Percentage: 17.083333333333332,
  },
  {
    "Sl no": 1637,
    "CANDIDATE ID": 2862,
    R: 17,
    W: 39,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 1638,
    "CANDIDATE ID": 2863,
    R: 18,
    W: 24,
    Total: 48,
    Percentage: 20,
  },
  {
    "Sl no": 1639,
    "CANDIDATE ID": 2864,
    R: 17,
    W: 22,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1640,
    "CANDIDATE ID": 2865,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1641,
    "CANDIDATE ID": 2866,
    R: 20,
    W: 25,
    Total: 55,
    Percentage: 22.917,
  },
  {
    "Sl no": 1642,
    "CANDIDATE ID": 2867,
    R: 20,
    W: 23,
    Total: 57,
    Percentage: 23.75,
  },
  {
    "Sl no": 1643,
    "CANDIDATE ID": 2868,
    R: 11,
    W: 41,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 1644,
    "CANDIDATE ID": 2869,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 1645,
    "CANDIDATE ID": 2870,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1646,
    "CANDIDATE ID": 2871,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1647,
    "CANDIDATE ID": 2872,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.416666666666668,
  },
  {
    "Sl no": 1648,
    "CANDIDATE ID": 2873,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1649,
    "CANDIDATE ID": 2874,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1650,
    "CANDIDATE ID": 2875,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1651,
    "CANDIDATE ID": 2876,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1652,
    "CANDIDATE ID": 2876,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1653,
    "CANDIDATE ID": 2877,
    R: 10,
    W: 47,
    Total: -7,
    Percentage: null,
  },
  {
    "Sl no": 1654,
    "CANDIDATE ID": 2879,
    R: 7,
    W: 21,
    Total: 7,
    Percentage: 2.917,
  },
  {
    "Sl no": 1655,
    "CANDIDATE ID": 2880,
    R: 9,
    W: 29,
    Total: 7,
    Percentage: 2.9166666666666665,
  },
  {
    "Sl no": 1656,
    "CANDIDATE ID": 2881,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1657,
    "CANDIDATE ID": 2882,
    R: 15,
    W: 32,
    Total: 28,
    Percentage: 11.666666666666666,
  },
  {
    "Sl no": 1658,
    "CANDIDATE ID": 2883,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1659,
    "CANDIDATE ID": 2884,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1660,
    "CANDIDATE ID": 2885,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583333333333334,
  },
  {
    "Sl no": 1661,
    "CANDIDATE ID": 2886,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1662,
    "CANDIDATE ID": 2887,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833333333333336,
  },
  {
    "Sl no": 1663,
    "CANDIDATE ID": 2888,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1664,
    "CANDIDATE ID": 2889,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1665,
    "CANDIDATE ID": 2890,
    R: 12,
    W: 47,
    Total: 1,
    Percentage: 0.417,
  },
  {
    "Sl no": 1666,
    "CANDIDATE ID": 2891,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1667,
    "CANDIDATE ID": 2892,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1668,
    "CANDIDATE ID": 2893,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1669,
    "CANDIDATE ID": 2894,
    R: 16,
    W: 42,
    Total: 22,
    Percentage: 9.166666666666666,
  },
  {
    "Sl no": 1670,
    "CANDIDATE ID": 2895,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1671,
    "CANDIDATE ID": 2896,
    R: 14,
    W: 45,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1672,
    "CANDIDATE ID": 2899,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333333333333332,
  },
  {
    "Sl no": 1673,
    "CANDIDATE ID": 2900,
    R: 11,
    W: 49,
    Total: -5,
    Percentage: null,
  },
  {
    "Sl no": 1674,
    "CANDIDATE ID": 2902,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1675,
    "CANDIDATE ID": 2903,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1676,
    "CANDIDATE ID": 2904,
    R: 17,
    W: 33,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1677,
    "CANDIDATE ID": 2905,
    R: 18,
    W: 31,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1678,
    "CANDIDATE ID": 2906,
    R: 18,
    W: 28,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 1679,
    "CANDIDATE ID": 2907,
    R: 18,
    W: 28,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 1680,
    "CANDIDATE ID": 2908,
    R: 18,
    W: 28,
    Total: 44,
    Percentage: 18.333,
  },
  {
    "Sl no": 1681,
    "CANDIDATE ID": 2910,
    R: 13,
    W: 29,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 1682,
    "CANDIDATE ID": 2911,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1683,
    "CANDIDATE ID": 2912,
    R: 13,
    W: 44,
    Total: 8,
    Percentage: 3.333,
  },
  {
    "Sl no": 1684,
    "CANDIDATE ID": 2913,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1685,
    "CANDIDATE ID": 2914,
    R: 13,
    W: 41,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1686,
    "CANDIDATE ID": 2915,
    R: 14,
    W: 41,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1687,
    "CANDIDATE ID": 2916,
    R: 21,
    W: 34,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1688,
    "CANDIDATE ID": 2917,
    R: 17,
    W: 36,
    Total: 32,
    Percentage: 13.333,
  },
  {
    "Sl no": 1689,
    "CANDIDATE ID": 2918,
    R: 16,
    W: 40,
    Total: 24,
    Percentage: 10,
  },
  {
    "Sl no": 1690,
    "CANDIDATE ID": 2919,
    R: 10,
    W: 43,
    Total: -3,
    Percentage: -1.25,
  },
  {
    "Sl no": 1691,
    "CANDIDATE ID": 2920,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1692,
    "CANDIDATE ID": 2921,
    R: 16,
    W: 41,
    Total: 23,
    Percentage: 9.583333333333334,
  },
  {
    "Sl no": 1693,
    "CANDIDATE ID": 2922,
    R: 10,
    W: 36,
    Total: 4,
    Percentage: 1.667,
  },
  {
    "Sl no": 1694,
    "CANDIDATE ID": 2923,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1695,
    "CANDIDATE ID": 2924,
    R: 17,
    W: 39,
    Total: 29,
    Percentage: 12.083,
  },
  {
    "Sl no": 1696,
    "CANDIDATE ID": 2925,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1697,
    "CANDIDATE ID": 2926,
    R: 25,
    W: 32,
    Total: 68,
    Percentage: 28.333,
  },
  {
    "Sl no": 1698,
    "CANDIDATE ID": 2927,
    R: 12,
    W: 22,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1699,
    "CANDIDATE ID": 2928,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1700,
    "CANDIDATE ID": 2928,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1701,
    "CANDIDATE ID": 2929,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1702,
    "CANDIDATE ID": 2930,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1703,
    "CANDIDATE ID": 2931,
    R: 17,
    W: 42,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1704,
    "CANDIDATE ID": 2932,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1705,
    "CANDIDATE ID": 2933,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1706,
    "CANDIDATE ID": 2934,
    R: 11,
    W: 47,
    Total: -3,
    Percentage: null,
  },
  {
    "Sl no": 1707,
    "CANDIDATE ID": 2935,
    R: 20,
    W: 38,
    Total: 42,
    Percentage: 17.5,
  },
  {
    "Sl no": 1708,
    "CANDIDATE ID": 2936,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1709,
    "CANDIDATE ID": 2937,
    R: 12,
    W: 25,
    Total: 23,
    Percentage: 9.583333333333334,
  },
  {
    "Sl no": 1710,
    "CANDIDATE ID": 2938,
    R: 15,
    W: 37,
    Total: 23,
    Percentage: 9.583,
  },
  {
    "Sl no": 1711,
    "CANDIDATE ID": 2939,
    R: 8,
    W: 21,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1712,
    "CANDIDATE ID": 2941,
    R: 17,
    W: 40,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1713,
    "CANDIDATE ID": 2942,
    R: 15,
    W: 32,
    Total: 28,
    Percentage: 11.667,
  },
  {
    "Sl no": 1714,
    "CANDIDATE ID": 2943,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1715,
    "CANDIDATE ID": 2944,
    R: 16,
    W: 33,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1716,
    "CANDIDATE ID": 2945,
    R: 11,
    W: 41,
    Total: 3,
    Percentage: 1.25,
  },
  {
    "Sl no": 1717,
    "CANDIDATE ID": 2946,
    R: 18,
    W: 41,
    Total: 31,
    Percentage: 12.917,
  },
  {
    "Sl no": 1718,
    "CANDIDATE ID": 2947,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1719,
    "CANDIDATE ID": 2948,
    R: 8,
    W: 23,
    Total: 9,
    Percentage: 3.75,
  },
  {
    "Sl no": 1720,
    "CANDIDATE ID": 2949,
    R: 8,
    W: 12,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1721,
    "CANDIDATE ID": 2950,
    R: 13,
    W: 30,
    Total: 22,
    Percentage: 9.167,
  },
  {
    "Sl no": 1722,
    "CANDIDATE ID": 2951,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1723,
    "CANDIDATE ID": 2952,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1724,
    "CANDIDATE ID": 2953,
    R: 10,
    W: 49,
    Total: -9,
    Percentage: null,
  },
  {
    "Sl no": 1725,
    "CANDIDATE ID": 2954,
    R: 31,
    W: 29,
    Total: 95,
    Percentage: 39.583,
  },
  {
    "Sl no": 1726,
    "CANDIDATE ID": 2955,
    R: 17,
    W: 21,
    Total: 47,
    Percentage: 19.583333333333332,
  },
  {
    "Sl no": 1727,
    "CANDIDATE ID": 2956,
    R: 19,
    W: 27,
    Total: 49,
    Percentage: 20.416666666666668,
  },
  {
    "Sl no": 1728,
    "CANDIDATE ID": 2957,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1729,
    "CANDIDATE ID": 2958,
    R: 11,
    W: 32,
    Total: 12,
    Percentage: 5,
  },
  {
    "Sl no": 1730,
    "CANDIDATE ID": 2959,
    R: 20,
    W: 33,
    Total: 47,
    Percentage: 19.583,
  },
  {
    "Sl no": 1731,
    "CANDIDATE ID": 2960,
    R: 17,
    W: 28,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1732,
    "CANDIDATE ID": 2961,
    R: 21,
    W: 34,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1733,
    "CANDIDATE ID": 2962,
    R: 18,
    W: 20,
    Total: 52,
    Percentage: 21.667,
  },
  {
    "Sl no": 1734,
    "CANDIDATE ID": 2963,
    R: 14,
    W: 26,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1735,
    "CANDIDATE ID": 2964,
    R: 17,
    W: 22,
    Total: 46,
    Percentage: 19.167,
  },
  {
    "Sl no": 1736,
    "CANDIDATE ID": 2965,
    R: 16,
    W: 14,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1737,
    "CANDIDATE ID": 2966,
    R: 19,
    W: 31,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1738,
    "CANDIDATE ID": 2967,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1739,
    "CANDIDATE ID": 2968,
    R: 20,
    W: 39,
    Total: 41,
    Percentage: 17.083,
  },
  {
    "Sl no": 1740,
    "CANDIDATE ID": 2969,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1741,
    "CANDIDATE ID": 2970,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1742,
    "CANDIDATE ID": 2971,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1743,
    "CANDIDATE ID": 2972,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1744,
    "CANDIDATE ID": 2973,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1745,
    "CANDIDATE ID": 2974,
    R: 20,
    W: 40,
    Total: 40,
    Percentage: 16.667,
  },
  {
    "Sl no": 1746,
    "CANDIDATE ID": 2975,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1747,
    "CANDIDATE ID": 2976,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1748,
    "CANDIDATE ID": 2977,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1749,
    "CANDIDATE ID": 2977,
    R: 19,
    W: 41,
    Total: 35,
    Percentage: 14.583,
  },
  {
    "Sl no": 1750,
    "CANDIDATE ID": 2978,
    R: 18,
    W: 42,
    Total: 30,
    Percentage: 12.5,
  },
  {
    "Sl no": 1751,
    "CANDIDATE ID": 2979,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1752,
    "CANDIDATE ID": 2980,
    R: 10,
    W: 44,
    Total: -4,
    Percentage: null,
  },
  {
    "Sl no": 1753,
    "CANDIDATE ID": 2981,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1754,
    "CANDIDATE ID": 2982,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1755,
    "CANDIDATE ID": 2983,
    R: 12,
    W: 28,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1756,
    "CANDIDATE ID": 2984,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1757,
    "CANDIDATE ID": 2985,
    R: 13,
    W: 46,
    Total: 6,
    Percentage: 2.5,
  },
  {
    "Sl no": 1758,
    "CANDIDATE ID": 2986,
    R: 12,
    W: 35,
    Total: 13,
    Percentage: 5.417,
  },
  {
    "Sl no": 1759,
    "CANDIDATE ID": 2987,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1760,
    "CANDIDATE ID": 2988,
    R: 22,
    W: 38,
    Total: 50,
    Percentage: 20.833,
  },
  {
    "Sl no": 1761,
    "CANDIDATE ID": 2989,
    R: 21,
    W: 39,
    Total: 45,
    Percentage: 18.75,
  },
  {
    "Sl no": 1762,
    "CANDIDATE ID": 2990,
    R: 27,
    W: 28,
    Total: 80,
    Percentage: 33.33333333333333,
  },
  {
    "Sl no": 1763,
    "CANDIDATE ID": 2991,
    R: 28,
    W: 30,
    Total: 82,
    Percentage: 34.166666666666664,
  },
  {
    "Sl no": 1764,
    "CANDIDATE ID": 2992,
    R: 30,
    W: 30,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 1765,
    "CANDIDATE ID": 2993,
    R: 28,
    W: 32,
    Total: 18,
    Percentage: 7.5,
  },
  {
    "Sl no": 1766,
    "CANDIDATE ID": 2994,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.33333333333333,
  },
  {
    "Sl no": 1767,
    "CANDIDATE ID": 2995,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 1768,
    "CANDIDATE ID": 2996,
    R: 28,
    W: 32,
    Total: 80,
    Percentage: 33.333,
  },
  {
    "Sl no": 1769,
    "CANDIDATE ID": 2997,
    R: 29,
    W: 31,
    Total: 85,
    Percentage: 35.41666666666667,
  },
  {
    "Sl no": 1770,
    "CANDIDATE ID": 2998,
    R: 30,
    W: 30,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 1771,
    "CANDIDATE ID": 2999,
    R: 27,
    W: 33,
    Total: 75,
    Percentage: 31.25,
  },
  {
    "Sl no": 1772,
    "CANDIDATE ID": 3000,
    R: 12,
    W: 22,
    Total: 26,
    Percentage: 10.833,
  },
  {
    "Sl no": 1773,
    "CANDIDATE ID": 3001,
    R: 14,
    W: 46,
    Total: 10,
    Percentage: 4.167,
  },
  {
    "Sl no": 1774,
    "CANDIDATE ID": 3002,
    R: 30,
    W: 30,
    Total: 90,
    Percentage: 37.5,
  },
  {
    "Sl no": 1775,
    "CANDIDATE ID": 3003,
    R: 25,
    W: 31,
    Total: 69,
    Percentage: 28.749999999999996,
  },
  {
    "Sl no": 1776,
    "CANDIDATE ID": 3004,
    R: 24,
    W: 35,
    Total: 61,
    Percentage: 25.416666666666664,
  },
  {
    "Sl no": 1777,
    "CANDIDATE ID": 3005,
    R: 24,
    W: 32,
    Total: 64,
    Percentage: 26.666666666666668,
  },
  {
    "Sl no": 1778,
    "CANDIDATE ID": 3006,
    R: 15,
    W: 44,
    Total: 16,
    Percentage: 6.666666666666667,
  },
  {
    "Sl no": 1779,
    "CANDIDATE ID": 3007,
    R: 24,
    W: 36,
    Total: 60,
    Percentage: 25,
  },
  {
    "Sl no": 1780,
    "CANDIDATE ID": 3008,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1781,
    "CANDIDATE ID": 3009,
    R: 16,
    W: 44,
    Total: 20,
    Percentage: 8.333,
  },
  {
    "Sl no": 1782,
    "CANDIDATE ID": 3010,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1783,
    "CANDIDATE ID": 3011,
    R: 19,
    W: 20,
    Total: 56,
    Percentage: 23.333,
  },
  {
    "Sl no": 1784,
    "CANDIDATE ID": 3012,
    R: 27,
    W: 32,
    Total: 76,
    Percentage: 31.667,
  },
  {
    "Sl no": 1785,
    "CANDIDATE ID": 3013,
    R: 25,
    W: 27,
    Total: 73,
    Percentage: 30.417,
  },
  {
    "Sl no": 1786,
    "CANDIDATE ID": 3014,
    R: 17,
    W: 43,
    Total: 25,
    Percentage: 10.417,
  },
  {
    "Sl no": 1787,
    "CANDIDATE ID": 3015,
    R: 16,
    W: 43,
    Total: 21,
    Percentage: 8.75,
  },
  {
    "Sl no": 1788,
    "CANDIDATE ID": 3016,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1789,
    "CANDIDATE ID": 3017,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1790,
    "CANDIDATE ID": 3018,
    R: 15,
    W: 45,
    Total: 15,
    Percentage: 6.25,
  },
  {
    "Sl no": 1791,
    "CANDIDATE ID": 3019,
    R: 10,
    W: 29,
    Total: 11,
    Percentage: 4.583,
  },
  {
    "Sl no": 1792,
    "CANDIDATE ID": 3020,
    R: 12,
    W: 48,
    Total: null,
    Percentage: null,
  },
  {
    "Sl no": 1793,
    "CANDIDATE ID": 3021,
    R: 13,
    W: 47,
    Total: 5,
    Percentage: 2.083,
  },
  {
    "Sl no": 1794,
    "CANDIDATE ID": 3022,
    R: 15,
    W: 42,
    Total: 18,
    Percentage: 7.5,
  },
];
