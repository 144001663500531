import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.snow.css";
import "./App.scss";
import { routes } from "./utils/Routes";
import Home from "./pages/HomePage";
import DashBoard from "./pages/DashBoard";
import JoinSession from "./components/JoinSession/JoinSession";
import "react-modal-video/css/modal-video.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./components/About/About";
import NavTopnav from "./components/NavTopnav/NavTopnav";
import AcademicYear from "./components/Layout/Administrator/AcademicYear";
import Academic from "./components/Layout/Academic/Academic";
import SchoolSection from "./components/Layout/Academic/SchoolSection";
import SchoolSubject from "./components/Layout/Academic/SchoolSubject";
import Designation from "./components/Layout/HumanResource/Designation";
import Department from "./components/Layout/HumanResource/Department";
import Employee from "./components/Layout/HumanResource/Employee";
import AddEmployee from "./components/Layout/HumanResource/AddEmployee";
import ExamGrade from "./components/Layout/Exam/ExamGrade";
import UserRole from "./components/Layout/Administrator/UserRole";
import ExamTerm from "./components/Layout/Exam/ExamTerm";
import ExamSchedule from "./components/Layout/Exam/ExamSchedule";
import HallTicket from "./components/Layout/Exam/HallTicket";
import AdmitStudent from "./components/Layout/Student/AdmitStudent";
import AddAdmitStudent from "./components/Layout/Student/AddAdmitStudent";
import ExamAttendance from "./components/Layout/ExamMark/ExamAttendance";
import { jwtDecode } from "jwt-decode";

import Reshuffle from "./components/Layout/Student/Reshuffle";
import Promotion from "./components/Layout/Student/Promotion";
import Settings from "./components/Layout/Settings/Settings";
import AnnounceNews from "./components/Layout/Announcement/AnnounceNews";
import AnnounceHoliday from "./components/Layout/Announcement/AnnounceHoliday";
import Notice from "./components/Layout/Announcement/Notice";
import ClassRoutine from "./components/Layout/ClassRoutine/ClassRoutine";
import ManageMark from "./components/Layout/ExamMark/ManageMark";
import ExamMark from "./components/Layout/ExamMark/ExamResult";
import StudyCertificate from "./components/Layout/Certificate/StudyCertificate";
import EditStudent from "./components/Layout/Student/EditStudent";
import EditEmployee from "./components/Layout/HumanResource/EditEmployee";
import TransferCertificate from "./components/Layout/Certificate/TransferCertificate";
import MarksCard from "./components/Layout/ExamMark/MarksCard";
import Compose from "./components/Layout/Communication/Compose";
import Report from "./components/Layout/Communication/Report";
import TextMessage from "./components/Layout/Communication/TextMessage";
import RolePermission from "./components/Layout/Administrator/RolePermission";
import AdminRolePermission from "./components/Layout/Administrator/AdminRolePermission";
import AssignRolePermission from "./components/Layout/Administrator/AssignRolePermission";
import StudentAttendance from "./components/Layout/Attendance/StudentAttendance";
import EmployeeAttendance from "./components/Layout/Attendance/EmployeeAttendance";
import Sidebar1 from "./components/Navigation/components/Sidebar1";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import dataSidebar from "./components/Navigation/components/DataSidebar";
import { NavLink } from "react-router-dom";
import Consolidated from "./components/Layout/ExamMark/Consolidated";
import NewRolePermission from "./components/Layout/Administrator/NewRolePermission";
import { urls } from "./services/urlConstant";
import { get } from "./services/apis";
import StudentTable from "./components/Layout/Student/StudentTable";
import Login from "./components/Layout/Authentication/Login";
import ResetPassword from "./components/Layout/ResetPassword/ResetPassword";
import { objHas } from "./utils/utils";
import Event from "./components/Layout/Events/Event";
import ForgotPassword from "./components/Layout/ForgotPassword";
import BulkAdmission from "./components/Layout/Student/BulkAdmission";
import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";
import ResetPasswordUser from "./components/Layout/ResetPassword/ResetPasswordUser";
import Addmission from "./components/Addmission";
import Academics from "./components/Academics";
import Primary from "./components/Primary";
import HighSchool from "./components/HighSchool";
import Library from "./components/Library";
import Sports from "./components/Sports";
import Canteen from "./components/Canteen";
import Transport from "./components/Transport";
import EduSmart from "./components/EduSmart";
import Assignment from "./components/Layout/Assignment/Assignment";
import ContactUs from "./components/ContactUs";
import PublicRoute from "./PublicRoute";
import Results from "./components/Results";
import Gallery from "./components/Layout/Gallery/gallery";
import GalleryHome from "./components/GalleryHome";

import OurSchool from "./components/Our school/OurSchool";
import Principal from "./components/PrincipalDesk/Principal";
import Faculty from "./components/Faculty/Faculty";
import SettingContext from "./context/SettingsContext";
import EventHome from "./components/EventHome";
import UniqueFeatures from "./components/UniqueFeatures";
import KnowledgeOfParents from "./components/KnowledgeOfParents";
import Careers from "./components/Careers";
import HomeNews from "./components/HomeNews";
import HomeNewsView from "./components/HomeNewsView";
import HomeNotice from "./components/HomeNotice";
import HomeNoticeView from "./components/HomeNoticeView";
import EmployeeView from "./components/Layout/HumanResource/EmployeeView";
import AssignmentHome from "./components/AssignmentHome";
import AssignmentView from "./components/AssignmentView";
import Credential from "./components/Layout/Student/Credential";
import Birthday from "./components/Layout/Birthday/Birthday";
import Awards from "./components/Layout/Awards/Awards";
import AwardsAndAchivments from "./components/AwardsAndAchivments";
import Overview from "./components/Layout/Student/Overview";
import LatestEventsById from "./components/LatestEventsById";
import StudentBulkPhoto from "./components/Layout/Student/StudentBulkPhoto";
import Toppers from "./components/toppers";
import ReceiptBook from "./components/Layout/Fees/ReceiptBook";
import QuickFee from "./components/Layout/Fees/QuickFee";
import QuickFeeCollection from "./components/Layout/Fees/QuickFeeCollection";
import FeeCategoryList from "./components/Layout/Fees/FeeCategoryList";
import FeeRemittance from "./components/Layout/Fees/FeeRemittance";
import DetailedFeeCollection from "./components/Layout/Fees/DetailedFeeCollection";
import BalanceFeeReport from "./components/Layout/Fees/balanceFeeReport";
import FeeOverview from "./components/Layout/Fees/feeOverview";
import Credentails from "./components/Layout/Communication/Credentials";
import StudentDashbord from "./pages/StudentDashbord";
import Hostel from "./components/Hostel/Hostel";
import ManageRoom from "./components/Hostel/ManageRoom";
import ManageRoomType from "./components/Hostel/ManageRoomType";
import ManageRoomFee from "./components/Hostel/ManageRoomFee";
import HostelMember from "./components/Hostel/HostelMember";
import StudentLibrary from "./components/StudentLibrary/StudentLibrary";
import Periodical from "./components/StudentLibrary/Periodical";
import StudentMember from "./components/StudentLibrary/StudentMember";
import StudentIssue from "./components/StudentLibrary/StudentIssue";
import EmployeeMember from "./components/StudentLibrary/EmployeeMember";
import EmployeeIssue from "./components/StudentLibrary/EmployeeIssue";
import Vehicle from "./components/Transport/Vehicle";
import ManageRoute from "./components/Transport/ManageRoute";
import Trips from "./components/Transport/Trips";
import TransportMember from "./components/Transport/TransportMember";
import VehicleLog from "./components/Transport/VehicleLog";
import VehicleMaintenance from "./components/Transport/VehicleMaintenance";
import ManageStopFee from "./components/Transport/ManageStopFee";
import { TableDrawer } from "./context/DrawerComponent";
import SplashNews from "./components/Layout/Announcement/SplashNews";
import SalaryGrade from "./components/Layout/Payroll/SalaryGrade";
import SalaryPayment from "./components/Layout/Payroll/SalaryPayment";
import PaymentHistroy from "./components/Layout/Payroll/PaymentHistory";
// import StudentCheckOut from "./components/VisitorInfo/StudentCheckout";
import VisitoryInfo from "./components/VisitorInfo/VisitoInfo";
import LibraryReport from "./components/Layout/Report/LibraryReport";
import StudentYearlyAttendence from "./components/Layout/Report/StudentYearlyAttendance";
import TeacherAttendence from "./components/Layout/Report/TeacherAttendance";
import StudentAttendenceReport from "./components/Layout/Report/StudentAttendanceReport";
import TeacherYearlyAttendence from "./components/Layout/Report/TeacherYearlyAttendance";
import EmployeeAttendence from "./components/Layout/Report/EmployeeAttendance";
import EmployeeYearlyAttendence from "./components/Layout/Report/EmployeeYearlyAttendance";
import StudentReport from "./components/Layout/Report/StudentReport";
import StudentActivityReport from "./components/Layout/Report/StudentActivityReport";
import LeaveType from "./components/Layout/LeaveManagement/LeaveType";
import EmployeeLeave from "./components/Layout/LeaveManagement/EmployeeLeave";
import StudentsLeave from "./components/Layout/LeaveManagement/StudentsLeave";
import TeacherLeave from "./components/Layout/LeaveManagement/TeacherLeave";
import LeaveReport from "./components/Layout/LeaveManagement/LeaveReport";
import AboutFounder from "./components/About/AboutFounder";
import DanceAndSinging from "./components/DanceAndSinging";
import OverView from "./components/About/OverView";
import VisionMission from "./components/About/VisionMission";
import EventDetails from "./components/SchoolCard/EventDetails";
import CourseDetails from "./components/HomeComponents/CourseDetails";
import DrawerWidthContext from "./context/DrawerWidthContext";
import ScrollToTop from "./components/ScrollToTop";
import StudentCheckout from "./components/VisitorInfo/StudentCheckout";
import PreAdmission from "./components/PreAdmission";

// theme 2 components
import Addmission2 from "./Components2/Admission";
import OverView2 from "./Components2/about/Overview";
import AboutFounder2 from "./NavbarComponents/AboutFounder";
import VisionMission2 from "./NavbarComponents/VisionandMission";
import ContactUs2 from "./Components2/ConatctUs";
import Canteen2 from "./Components2/Category/Facilities/Food";
import Library2 from "./Components2/Category/Facilities/Library";
import Transport2 from "./Components2/Category/Facilities/Transport";
import DanceAndSinging2 from "./Components2/Category/Facilities/Dance";
import LabFacilities from "./Components2/Category/Facilities/Lab";
import Results2 from "./Components2/Results";
import NewsDetails from "./Components2/NewsAndNotice/NewsDetails";
import GalleryHome2 from "./Components2/HomePage/GalleryHome";
import EventInfo from "./Components2/events/EventInfo";
import Detail from "./ourblog/Detail";
import ManageInstitute from "./components/Layout/Settings/ManageInstitute";
import IssueDetails from "./components/Layout/Inventory/IssueDetails";
import ItemInventory from "./components/Layout/Inventory/ItemInventory";
import Item from "./components/Layout/Inventory/Item";

import Vendor from "./components/Layout/Inventory/Vendor";
import Transaction from "./components/Layout/Inventory/Transaction";
import Reconciliation from "./components/Layout/Fees/Reconciliation";
// import Issue from "./components/Layout/Inventory/Issue";
import SplashNewsDisplay from "./components/Layout/Announcement/SplashNewsDisplay";
import SplashNewsHorizontal from "./components/Layout/Announcement/SplashNewsHorizontal";
import AddSetting from "./components/Layout/Settings/AddSetting";
import PasswordReset from "./components/Layout/NavbarProfile/PasswordReset";
import NavbarProfile from "./components/Layout/NavbarProfile/NavbarProfile";
import PageNotFound from "./pages/PageNotFound";
import OffBoarding from "./components/Layout/HumanResource/OffBoarding";
import ExperienceLetter from "./components/Layout/HumanResource/ExperienceLetter";
import Gaudian from "./components/Layout/Gaudian/Gaudian";
import OfferLetter from "./components/Layout/HumanResource/OfferLetter.components";
import RelievingLetterComponents from "./components/Layout/HumanResource/RelievingLetter.components";
import Enquiries from "./components/PreAdmission/Enquiries";
import PreExams from "./components/PreAdmission/PreExams";
import PreExamShedules from "./components/PreAdmission/PreExamshedules";
import Tickets from "./components/Layout/Tickets/Tickets";
import TeacherActivity from "./components/Layout/Teacher/TeacherAtivity";
import TransportFee from "./components/Transport/TransportFee";
import StudentActivity from "./components/Layout/Student/StudentActivity";
import QuickAdmit from "./components/Layout/Student/QuickAdmit";
import UserTypeContext from "./context/UserTypeContext";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/styles";
import SubjectWiseReport from "./components/Layout/ExamMark/SubjectWiseReport";
import DivisionwiseReport from "./components/Layout/ExamMark/DivisionwiseReport";
import RoleUpdateCountContext from "./context/RoleUpdateCountContext";
import Products from "./components/Layout/Lms/Course/products";
import AddCourse from "./components/Layout/Lms/Course/AddCourse";
import UploadCourse from "./components/Layout/Lms/UploadCourse/UploadCourse";
import Room from "./components/Live/Room";
import MyCourses from "./components/MyCourses/MyCourses";
import VideoPlayer from "./components/MyCourses/VideoPlayer";
import Sell from "./components/Layout/Inventory/Sell";
import StudentVedioPlayer from "./pages/StudentVedioPlayer";
import Result from "./components/PreAdmission/Result";
import StudentLive from "./components/StudentAttendence/StudentLive";
import Storage from "./components/Layout/Lms/Storage";
import ReceiptBook2 from "./components/Layout/Fees2/ReceiptBook2";
import FeeMapCategoryList from "./components/Layout/Fees2/FeeMapCategoryList";
import CollectFeeDetails from "./components/Layout/Fees2/CollectFeeDetails";
import BalanceFeeReportNew from "./components/Layout/Fees2/BalanceFeeReportNew";
import FeeOverviewNew from "./components/Layout/Fees2/FeeOverviewNew";
import Live from "./components/Live/Live";
import ExamVenue from "./components/ExamConduct/ExamVenue";
import ExamConduct from "./components/ExamConduct/Examconduct";
import ExamQuestions from "./components/ExamConduct/ExamQuestions";
import Admission from "./components/Addmission";
import ScholarshipResults from "./pages/ScholarshipResults";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const excludePath = [
  "/home-assignment",
  "/home-notice",
  `/home-news`,
  "/home-news",
  "/home-careers",
  "/to-the-knowledge-of-parents",
  "/unique-features",
  "/pre-admission",
  "/home-gallery",
  "/results",
  "/contact-us",
  "/facilities/edusmart",
  "/facilities/transport",
  "/",
  "/login",
  "/forgot-password",
  "/principal",
  "/desk",
  "/facality",
  "/addmission",
  "/our-school",
  "/academics",
  "/academics/primary",
  "/academics/high-school",
  "/facilities/library",
  "/home-gallery",
  "/facilities/sports",
  "/facilities/canteen",
  "/view-assignment",
  "/about/founder",
  "/about/visionandmission",
  "/about/overview",
  "/facilities/dance-and-singing",
  "/eventdetails",
  "/courseDetails",
  "/unique-features",
  "/to-the-knowledge-of-parents",
  "/facilities/labs",
  "/pre-admission/exam-conduct",
  "/details/pre-admission/",
  "/home/events",
  "/scholarship-results",
];

function App() {
  const [isReady, setIsReady] = useState(false);
  const [roleUpdateCount, setRoleUpdateCount] = useState(0);
  const location = useLocation();
  const { pathname } = location;
  let updateNav = false;
  const token = window.localStorage.getItem(process.env.REACT_APP_TOKEN);
  if (token) updateNav = true;
  const [reRender, setRender] = useState(1);
  const [showDrawerHeader, setShowDrawerHeader] = useState(true);
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState({
    schoolName: "ABC School",
    email: "abc@gmail.com",
    phone: 9999999999,
    themeOrder: 1,
    deleted: false,
  });
  const [drawerWidth, setDraweWidth] = useState(0);
  const [canShowDrawer, setCanShowDrawer] = useState(false);
  const [settingsUpdateCount, setSettingsUpdateCount] = useState(0);
  const [notificationsUpdateCount, setNotificationsUpdateCount] = useState(0);

  const [popupData, setPopupData] = useState({
    open: false,
    data: null,
  });
  const [horizontalData, setHorizontalData] = useState([]);

  const [userType, setUserType] = useState("student");

  const getCurrentUser = async () => {
    try {
      let token = window.localStorage.getItem(process.env.REACT_APP_TOKEN);
      if (!token) {
        // window.location.href = "/";
      } else {
        let decodedToken = jwtDecode(token);
        if (decodedToken.userType === "employee") {
          console.log("Employee");
          setUserType("employee");
        } else if (decodedToken.userType === "student") {
          console.log("student");
          setUserType("student");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleClosePopup = () => setPopupData({ open: false, data: null });

  const getSettings = async () => {
    try {
      const { data } = await get("/123/settings");
      setSettings(data.data);
      setSelectedSetting(
        data.data[0] || {
          schoolName: "ABC School",
          email: "abc@gmail.com",
          phone: 9999999999,
          themeOrder: 1,
          deleted: false,
        }
      );

      console.log(data.data, "settings");
    } catch (error) {
      console.log(error);
    }
    setIsReady(true);
  };

  useEffect(() => {
    getSettings();
  }, [settingsUpdateCount]);

  const [settingsContext, setSettingsContext] = useState({
    schoolName: "",
    schoolLogo: "",
    activeAcademicYear: "",
  });

  const getSplashNews = async () => {
    try {
      const { data } = await get(
        selectedSetting._id + urls.announceFlashNews.getFlashNews
      );
      if (data.data.length) {
        let allSplashNews = data.data;
        setHorizontalData(
          allSplashNews.filter(
            (s) => s.type === "Horizontal" && s.webView === "yes"
          )
        );
        setPopupData({
          open: false,
          data: allSplashNews.filter(
            (s) => s.type === "Popup" && s.webView === "yes"
          )[0],
        });
      } else {
        setHorizontalData([]);
        setPopupData({ open: false, data: null });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedSetting && selectedSetting._id) {
      getSplashNews();
    }
  }, [selectedSetting]);

  useEffect(() => {
    if (popupData.data) {
      setPopupData({ ...popupData, open: true });
    }
  }, [popupData.data]);

  useEffect(() => {
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/home/events") ||
      pathname.startsWith("/eventdetails") ||
      pathname.startsWith("/courseDetails") ||
      pathname.startsWith("/details") ||
      pathname.startsWith("/events") ||
      pathname.startsWith("/ourachievement/") ||
      pathname.startsWith("/pre-admission/exam-conduct") ||
      pathname.startsWith("/details/pre-admission/") ||
      pathname.startsWith("/scholarship-results") ||
      pathname.startsWith("/toppers-list");
    setShowDrawerHeader(!exclude);
  }, [location.pathname]);

  useEffect(() => {
    for (let path of excludePath) {
      if (pathname.startsWith(path)) {
        return setCanShowDrawer(false);
      }
    }
  }, [location.path]);

  const triggerRender = () => {
    setRender((prev) => prev + 1);
  };

  let [drawer, setDrawer] = useState(true);
  const [padding, setPadding] = useState("270px");

  useEffect(() => {
    if (drawer) {
      setPadding("270px");
    } else {
      setPadding("60px");
    }
  }, [drawer]);

  if (!isReady) return null;

  return (
    <ThemeProvider theme={theme}>
      <SettingContext.Provider
        value={{
          settingsContext,
          setSettingsContext,
          settings,
          setSettings,
          selectedSetting,
          setSelectedSetting,
          settingsUpdateCount,
          setSettingsUpdateCount,
          notificationsUpdateCount,
          setNotificationsUpdateCount,
        }}
      >
        <RoleUpdateCountContext.Provider
          value={{ roleUpdateCount, setRoleUpdateCount }}
        >
          <UserTypeContext.Provider value={{ userType, setUserType }}>
            <SplashNewsDisplay
              open={popupData.open}
              sharedData={popupData.data}
              handleClose={handleClosePopup}
            />

            {horizontalData.length ? (
              <SplashNewsHorizontal horizontalData={horizontalData} />
            ) : null}
            <ScrollToTop />
            <TableDrawer.Provider value={{ drawer, setDrawer }}>
              <DrawerWidthContext.Provider
                value={{ drawerWidth, setDraweWidth }}
              >
                <Box
                  sx={{
                    paddingLeft:
                      updateNav &&
                      !excludePath.includes(pathname) &&
                      userType === "employee" &&
                      showDrawerHeader
                        ? padding
                        : 0,
                  }}
                >
                  <CssBaseline />
                  {updateNav && <NavBar reRender={reRender} />}
                  <div className="App">
                    <Box component="main">
                      {showDrawerHeader && userType === "employee" && (
                        <DrawerHeader />
                      )}

                      {userType === "employee" ? (
                        <StylesProvider injectFirst>
                          <Routes>
                            <Route path="/" element={<NavTopnav />} exact>
                              <Route path="/" element={<Home />} />
                              <Route path="/about" element={<About />} />
                            </Route>
                            {/* Common */}
                            <Route
                              path="/login"
                              element={
                                <PublicRoute>
                                  <Login />
                                </PublicRoute>
                              }
                            />
                            <Route
                              path="/scholarship-results"
                              element={<ScholarshipResults />}
                            />
                            <Route
                              path="/dashboard/sd"
                              element={
                                <PrivateRoute>
                                  <StudentDashbord />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/moreproject"
                              element={<JoinSession />}
                              exact
                            />
                            <Route
                              path="/dashboard"
                              element={
                                <PrivateRoute roleFunction="dashboard">
                                  <DashBoard />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/academicyear"
                              element={
                                <PrivateRoute roleFunction="academicYear">
                                  <AcademicYear />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/class"
                              element={
                                <PrivateRoute roleFunction="class">
                                  <Academic />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/section"
                              element={
                                <PrivateRoute roleFunction="section">
                                  <SchoolSection />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/subject"
                              element={
                                <PrivateRoute roleFunction="subject">
                                  <SchoolSubject />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/designation"
                              element={
                                <PrivateRoute roleFunction="designation">
                                  <Designation />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/events"
                              element={
                                <PrivateRoute roleFunction="event">
                                  <Event />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/guardian"
                              element={
                                <PrivateRoute roleFunction="guardianFeedback">
                                  <Gaudian />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/department"
                              element={
                                <PrivateRoute roleFunction="department">
                                  <Department />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/off-boarding"
                              element={
                                <PrivateRoute roleFunction="offboarding">
                                  <OffBoarding />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/employee"
                              element={
                                <PrivateRoute roleFunction="employee">
                                  <Employee />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/experience"
                              element={
                                <PrivateRoute roleFunction="experienceletter">
                                  <ExperienceLetter />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/offer-letter"
                              element={
                                <PrivateRoute roleFunction="offerletter">
                                  <OfferLetter />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/relieving-letter"
                              element={
                                <PrivateRoute roleFunction="relievingletter">
                                  <RelievingLetterComponents />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/addemployee"
                              element={
                                <PrivateRoute roleFunction="employee">
                                  <AddEmployee />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/examgrade"
                              element={
                                <PrivateRoute roleFunction="examGrade">
                                  <ExamGrade />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/notifications"
                              element={
                                <PrivateRoute roleFunction="smsNotification">
                                  <TextMessage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/role"
                              element={
                                <PrivateRoute roleFunction="role">
                                  <UserRole />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/course"
                              element={
                                <PrivateRoute roleFunction="course">
                                  <Products />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/dashboard/lives"
                              element={
                                <PrivateRoute roleFunction="course">
                                  <StudentLive />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/storage"
                              element={
                                <PrivateRoute roleFunction="storage">
                                  <Storage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/addCourse"
                              element={
                                <PrivateRoute
                                  roleFunction="course"
                                  action="add"
                                >
                                  <AddCourse />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/course-content"
                              element={
                                <PrivateRoute roleFunction="courseContent">
                                  <UploadCourse />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/live"
                              element={
                                <PrivateRoute roleFunction="live">
                                  <Live />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/myCourses"
                              element={
                                <PrivateRoute roleFunction="course">
                                  <MyCourses />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/videoContent"
                              element={
                                <PrivateRoute>
                                  <VideoPlayer />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/room/:roomID"
                              element={
                                <PrivateRoute>
                                  <Room />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/examterm"
                              element={
                                <PrivateRoute roleFunction="examTerm">
                                  <ExamTerm />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/examschedule"
                              element={
                                <PrivateRoute roleFunction="examSchedule">
                                  <ExamSchedule />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/addSetting"
                              element={
                                <PrivateRoute
                                  roleFunction="setting"
                                  action="add"
                                >
                                  <AddSetting />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/exam/hallticket"
                              element={
                                <PrivateRoute roleFunction="hallTicket">
                                  <HallTicket />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/admitstudent"
                              element={
                                <PrivateRoute roleFunction="admitStudent">
                                  <StudentTable />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/addstudent"
                              element={
                                <PrivateRoute
                                  roleFunction="admitStudent"
                                  action="add"
                                >
                                  <AddAdmitStudent />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/examattendance"
                              element={
                                <PrivateRoute roleFunction="examAttendance">
                                  <ExamAttendance />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/teacher"
                              element={
                                <PrivateRoute roleFunction="teacherActivity">
                                  <TeacherActivity />
                                </PrivateRoute>
                              }
                            />
                            <Route path="/desk" element={<Faculty />} />
                            <Route
                              path="/managemark"
                              element={
                                <PrivateRoute roleFunction="studentMark">
                                  <ManageMark />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/forgot-password"
                              element={<ForgotPassword />}
                            />
                            <Route path="/event" element={<Event />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route
                              path="/examresult"
                              element={
                                <PrivateRoute roleFunction="examResult">
                                  <ExamMark />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/reshuffle"
                              element={
                                <PrivateRoute roleFunction="studentResuffle">
                                  <Reshuffle />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/promotion"
                              element={
                                <PrivateRoute roleFunction="studentPromotion">
                                  <Promotion />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/bulkadmission"
                              element={
                                <PrivateRoute roleFunction="studentBulkAdmission">
                                  <BulkAdmission />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/enquiries"
                              element={
                                <PrivateRoute roleFunction="preaddmissionEnquiry">
                                  <Enquiries />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/preexams"
                              element={
                                <PrivateRoute roleFunction="preaddmissionExam">
                                  <PreExams />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/preExamSchedules"
                              element={
                                <PrivateRoute roleFunction="preaddmissionExamSchedule">
                                  <PreExamShedules />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/dashboard/result"
                              element={
                                <PrivateRoute roleFunction="result">
                                  <Result />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/details/pre-admission/:id"
                              element={
                                <PrivateRoute>
                                  <ExamVenue />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/pre-admission/exam-conduct/:id"
                              element={
                                <PrivateRoute>
                                  <ExamConduct />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/exam/questions"
                              element={
                                <PrivateRoute>
                                  <ExamQuestions />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/settings/edit/:id"
                              element={
                                <PrivateRoute
                                  roleFunction="setting"
                                  action="update"
                                >
                                  <Settings />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/resetPassword"
                              element={
                                <PrivateRoute
                                  roleFunction="auth"
                                  action="update"
                                >
                                  <PasswordReset />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/profile"
                              element={
                                <PrivateRoute>
                                  <NavbarProfile />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/manageSetting"
                              element={
                                <PrivateRoute roleFunction="setting">
                                  <ManageInstitute />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/tickets"
                              element={
                                <PrivateRoute roleFunction="ticket">
                                  <Tickets />
                                </PrivateRoute>
                              }
                            />
                            {/* <Route
                      path="/issue"
                      element={
                        <PrivateRoute>
                          <Issue />
                        </PrivateRoute>
                      }
                    /> */}
                            <Route
                              path="/announcenews"
                              element={
                                <PrivateRoute roleFunction="news">
                                  <AnnounceNews />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/announceholiday"
                              element={
                                <PrivateRoute roleFunction="holiday">
                                  <AnnounceHoliday />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/notice"
                              element={
                                <PrivateRoute roleFunction="notice">
                                  <Notice />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/splashNews"
                              element={
                                <PrivateRoute roleFunction="splashNews">
                                  <SplashNews />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="routine"
                              element={
                                <PrivateRoute roleFunction="periodRoutine">
                                  <ClassRoutine />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/studycertificate"
                              element={
                                <PrivateRoute roleFunction="studyCertificate">
                                  <StudyCertificate />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/edit-student/:studentId"
                              element={
                                <PrivateRoute
                                  roleFunction="admitStudent"
                                  action="update"
                                >
                                  <EditStudent />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/edit-employee/:employeeId"
                              element={
                                <PrivateRoute
                                  roleFunction="employee"
                                  action="update"
                                >
                                  <EditEmployee />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/transfercertificate"
                              element={
                                <PrivateRoute roleFunction="transferCertificate">
                                  <TransferCertificate />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/marks-card"
                              element={
                                <PrivateRoute roleFunction="marksCard">
                                  <MarksCard />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/compose"
                              element={
                                <PrivateRoute roleFunction="sendSms">
                                  <Compose />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/sms-report"
                              element={
                                <PrivateRoute roleFunction="smsReport">
                                  <Report />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/role-permission"
                              element={
                                <PrivateRoute roleFunction="rolePermission">
                                  <RolePermission />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/role-permission/:id"
                              element={
                                <PrivateRoute
                                  roleFunction="rolePermission"
                                  action="update"
                                >
                                  <AssignRolePermission />
                                </PrivateRoute>
                              }
                            />
                            {/* <Route
                              path="/admin-role-permission"
                              element={
                                <PrivateRoute>
                                  <AdminRolePermission />
                                </PrivateRoute>
                              }
                            /> */}
                            <Route
                              path="/student-attendance"
                              element={
                                <PrivateRoute roleFunction="studentAttendance">
                                  <StudentAttendance />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/employee-attendance"
                              element={
                                <PrivateRoute roleFunction="employeeAttendanceTest">
                                  <EmployeeAttendance />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/sidebar-1"
                              element={
                                <PrivateRoute>
                                  <Sidebar1 />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/consolidated"
                              element={
                                <PrivateRoute roleFunction="consolildated">
                                  <Consolidated />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/subjectwisereport"
                              element={
                                <PrivateRoute roleFunction="subjectwisereport">
                                  <SubjectWiseReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/divisionwisereport"
                              element={
                                <PrivateRoute roleFunction="divissionwisereport">
                                  <DivisionwiseReport />
                                </PrivateRoute>
                              }
                            />
                            {/* <Route
                              path="/new-role-permission"
                              element={
                                <PrivateRoute>
                                  <NewRolePermission />
                                </PrivateRoute>
                              }
                            /> */}
                            <Route
                              path="/receipt"
                              element={
                                <PrivateRoute roleFunction="receiptBookItem">
                                  <ReceiptBook />
                                </PrivateRoute>
                              }
                            />

                            {/* <Route
                              path="/quick-fee"
                              element={
                                <PrivateRoute>
                                  <QuickFee />
                                </PrivateRoute>
                              }
                            /> */}
                            {/* <Route
                              path="/quick-fee-collection"
                              element={
                                <PrivateRoute>
                                  <QuickFeeCollection />
                                </PrivateRoute>
                              }
                            /> */}
                            {/* <Route
                              path="/fee-remittance"
                              element={
                                <PrivateRoute roleFunction="">
                                  <FeeRemittance />
                                </PrivateRoute>
                              }
                            /> */}
                            <Route
                              path="/detailed-fee"
                              element={
                                <PrivateRoute roleFunction="collectFees">
                                  <DetailedFeeCollection />
                                </PrivateRoute>
                              }
                            />
                            {/* ==== FEES 2 === */}
                            <Route
                              path="/receipt-book"
                              element={
                                <PrivateRoute roleFunction="receiptBookItem">
                                  <ReceiptBook2 />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/collect-fee-detail"
                              element={
                                <PrivateRoute roleFunction="collectFees">
                                  <CollectFeeDetails />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/fee-map-category"
                              element={
                                <PrivateRoute roleFunction="feeCategory">
                                  <FeeMapCategoryList />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/balance-fee-report"
                              element={
                                <PrivateRoute roleFunction="balanceFeeReport">
                                  <BalanceFeeReportNew />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/fee-overview"
                              element={
                                <PrivateRoute roleFunction="feeOverview">
                                  <FeeOverviewNew />
                                </PrivateRoute>
                              }
                            />
                            {/* =======||======= */}

                            <Route
                              path="/feecategorylist"
                              element={
                                <PrivateRoute roleFunction="feeCategory">
                                  <FeeCategoryList />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/balancefeereport"
                              element={
                                <PrivateRoute roleFunction="balanceFeeReport">
                                  <BalanceFeeReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/feeoverview"
                              element={
                                <PrivateRoute roleFunction="feeOverview">
                                  <FeeOverview />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="dashboard/fee/reconciliation"
                              element={
                                <PrivateRoute roleFunction="reconsiliation">
                                  <Reconciliation />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/reset-password"
                              element={
                                <PrivateRoute roleFunction="auth">
                                  <ResetPassword />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/event"
                              element={
                                <PrivateRoute roleFunction="event">
                                  <Event />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/hostel"
                              element={
                                <PrivateRoute roleFunction="hostel">
                                  <Hostel />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/hostelroom"
                              element={
                                <PrivateRoute roleFunction="hostelroom">
                                  <ManageRoom />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/hostelroomtype"
                              element={
                                <PrivateRoute roleFunction="roomType">
                                  <ManageRoomType />
                                </PrivateRoute>
                              }
                            />
                            {/* <Route
                              path="/dashboard/hostelroomfee"
                              element={
                                <PrivateRoute>
                                  <ManageRoomFee />
                                </PrivateRoute>
                              }
                            /> */}
                            <Route
                              path="/dashboard/hostelmember"
                              element={
                                <PrivateRoute roleFunction="hostelmember">
                                  <HostelMember />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/grade"
                              element={
                                <PrivateRoute roleFunction="salaryGrade">
                                  <SalaryGrade />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/payment"
                              element={
                                <PrivateRoute roleFunction="salaryPayment">
                                  <SalaryPayment />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/librarybooks"
                              element={
                                <PrivateRoute roleFunction="book">
                                  <StudentLibrary />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/libraryperiodical"
                              element={
                                <PrivateRoute roleFunction="periodical">
                                  <Periodical />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/studentlibrarymember"
                              element={
                                <PrivateRoute roleFunction="studentLibrary">
                                  <StudentMember />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/studentissue"
                              element={
                                <PrivateRoute roleFunction="issuereturn">
                                  <StudentIssue />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/employeelibrary"
                              element={
                                <PrivateRoute roleFunction="employeeLibrary">
                                  <EmployeeMember />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/employeeissue"
                              element={
                                <PrivateRoute roleFunction="empIssue">
                                  <EmployeeIssue />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/vehicle"
                              element={
                                <PrivateRoute roleFunction="vehicle">
                                  <Vehicle />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/route"
                              element={
                                <PrivateRoute roleFunction="route">
                                  <ManageRoute />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/trips"
                              element={
                                <PrivateRoute roleFunction="trips">
                                  <Trips />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/transportmember"
                              element={
                                <PrivateRoute roleFunction="transportMember">
                                  <TransportMember />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/transportfee"
                              element={
                                <PrivateRoute roleFunction="transportFee">
                                  <TransportFee />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/visitoryinfo"
                              element={
                                <PrivateRoute roleFunction="visitory">
                                  <VisitoryInfo />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/studentcheckout"
                              element={
                                <PrivateRoute roleFunction="studentCheckout">
                                  <StudentCheckout />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/maintenance"
                              element={
                                <PrivateRoute roleFunction="maintainance">
                                  <VehicleMaintenance />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/stop"
                              element={
                                <PrivateRoute roleFunction="transportmember">
                                  <ManageStopFee />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/log"
                              element={
                                <PrivateRoute roleFunction="vehicleLog">
                                  <VehicleLog />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/libraryReport"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <LibraryReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/yearlyAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <StudentYearlyAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/teacherAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <TeacherAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/studentAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <StudentAttendenceReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/teacherYearlyAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <TeacherYearlyAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/teacherYearlyAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <TeacherYearlyAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/employeeAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <EmployeeAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/employeeYearlyAttendance"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <EmployeeYearlyAttendence />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/studentReport"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <StudentReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/report/studentActivityReport"
                              element={
                                <PrivateRoute roleFunction="reports">
                                  <StudentActivityReport />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/leaveType"
                              element={
                                <PrivateRoute roleFunction="leaveType">
                                  <LeaveType />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/employeeLeave"
                              element={
                                <PrivateRoute roleFunction="employeeLeave">
                                  <EmployeeLeave />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/studentsLeave"
                              element={
                                <PrivateRoute roleFunction="studentLeave">
                                  <StudentsLeave />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/leaveReport"
                              element={
                                <PrivateRoute roleFunction="leaveReport">
                                  <LeaveReport />
                                </PrivateRoute>
                              }
                            />
                            {/* <Route
                              path="/bulkadmission"
                              element={
                                <PrivateRoute>
                                  <BulkAdmission />
                                </PrivateRoute>
                              }
                            /> */}
                            {/* <Route
                              path="/reset-password-user"
                              element={
                                <PrivateRoute>
                                  <ResetPasswordUser />
                                </PrivateRoute>
                              }
                            /> */}
                            <Route
                              path="/dashboard/assignment"
                              element={
                                <PrivateRoute>
                                  <Assignment />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/profile-view/:employeeId"
                              element={
                                <PrivateRoute>
                                  <EmployeeView />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/student-credential"
                              element={
                                <PrivateRoute>
                                  <Credential />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/awards"
                              element={
                                <PrivateRoute>
                                  <Awards />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/birthday"
                              element={
                                <PrivateRoute>
                                  <Birthday />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/student-bulk-photo"
                              element={
                                <PrivateRoute>
                                  <StudentBulkPhoto />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/send-credentials"
                              element={
                                <PrivateRoute>
                                  <Credentails />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/about/visionandmission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <VisionMission />
                                ) : (
                                  <VisionMission2 />
                                )
                              }
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/courseDetails/:id"
                              element={<CourseDetails />}
                            />
                            <Route
                              path="/about/founder"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <AboutFounder />
                                ) : (
                                  <AboutFounder2 />
                                )
                              }
                            />
                            <Route
                              path="/student-overview"
                              element={<Overview />}
                            />
                            <Route
                              path="/about/overview"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <OverView />
                                ) : (
                                  <OverView2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/dance-and-singing"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <DanceAndSinging />
                                ) : (
                                  <DanceAndSinging2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/canteen"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Canteen />
                                ) : (
                                  <Canteen2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/transport"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Transport />
                                ) : (
                                  <Transport2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route
                              path="/contact-us"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <ContactUs />
                                ) : (
                                  <ContactUs2 />
                                )
                              }
                            />
                            <Route
                              path="/pre-admission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PreAdmission />
                                ) : (
                                  <Addmission2 />
                                )
                              }
                            />
                            <Route
                              path="/results"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Results />
                                ) : (
                                  <Results2 />
                                )
                              }
                            />
                            <Route
                              path="/home-gallery"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <GalleryHome />
                                ) : (
                                  <GalleryHome2 />
                                )
                              }
                            />

                            <Route
                              path="/details/:id"
                              element={<NewsDetails />}
                            />
                            <Route
                              path="/home/events/:id"
                              element={<EventInfo />}
                            />
                            <Route
                              path="/ourachievement/:id"
                              element={<Detail />}
                            />
                            <Route path="/gallery" element={<Gallery />} />

                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />

                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />
                            <Route
                              path="/text-message"
                              element={<TextMessage />}
                            />
                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />
                            <Route
                              path="/bulkadmission"
                              element={
                                <PrivateRoute>
                                  <BulkAdmission />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/reset-password-user"
                              element={
                                <PrivateRoute roleFunction="resetUserPassword">
                                  <ResetPasswordUser />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/assignment"
                              element={
                                <PrivateRoute roleFunction="assignment">
                                  <Assignment />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/profile-view/:employeeId"
                              element={
                                <PrivateRoute roleFunction="employee">
                                  <EmployeeView />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/student-credential"
                              element={
                                <PrivateRoute roleFunction="studentCredential">
                                  <Credential />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/quickadmit"
                              element={
                                <PrivateRoute roleFunction="quickadmit">
                                  <QuickAdmit />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/studentactivity"
                              element={
                                <PrivateRoute roleFunction="studentactivity">
                                  <StudentActivity />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/awards"
                              element={
                                <PrivateRoute roleFunction="awards">
                                  <Awards />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/birthday"
                              element={
                                <PrivateRoute roleFunction="birthday">
                                  <Birthday />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/student-bulk-photo"
                              element={
                                <PrivateRoute
                                  roleFunction="admitStudent"
                                  action="update"
                                >
                                  <StudentBulkPhoto />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/send-credentials"
                              element={
                                <PrivateRoute roleFunction="sendCredential">
                                  <Credentails />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/vendor"
                              element={
                                <PrivateRoute roleFunction="vendor">
                                  <Vendor />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/item"
                              element={
                                <PrivateRoute roleFunction="item">
                                  <Item />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/sold"
                              element={
                                <PrivateRoute roleFunction="sold">
                                  <Sell />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/transaction"
                              element={
                                <PrivateRoute roleFunction="transaction">
                                  <Transaction />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/dashboard/itemInventory"
                              element={
                                <PrivateRoute roleFunction="itemInventory">
                                  <ItemInventory />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/dashboard/issueDetails"
                              element={
                                <PrivateRoute roleFunction="issueDetails">
                                  <IssueDetails />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path="/eventdetails/:id"
                              element={<EventDetails />}
                            />
                            {/* <Route path="/addmission" element={<Addmission />} /> */}
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/student-overview"
                              element={<Overview />}
                            />
                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/canteen"
                              element={<Canteen />}
                            />
                            <Route
                              path="/facilities/transport"
                              element={<Transport />}
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route path="/results" element={<Results />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />
                            <Route
                              path="/home-gallery2"
                              element={<GalleryHome />}
                            />
                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />
                            <Route
                              path="/text-message"
                              element={<TextMessage />}
                            />
                            <Route path="/event-home" element={<EventHome />} />

                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />

                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />
                            <Route path="/home-news" element={<HomeNews />} />
                            <Route
                              path="/home-news/:newsId"
                              element={<HomeNewsView />}
                            />
                            <Route
                              path="/home-notice"
                              element={<HomeNotice />}
                            />

                            <Route
                              path="/home-notice/:noticeId"
                              element={<HomeNoticeView />}
                            />
                            <Route
                              path="/home-assignment"
                              element={<AssignmentHome />}
                            />
                            <Route
                              path="/view-assignment"
                              element={<AssignmentView />}
                            />
                            <Route
                              path="/awards-home/:awardsId"
                              element={<AwardsAndAchivments />}
                            />
                            <Route
                              path="/events-home/:eventsId"
                              element={<LatestEventsById />}
                            />
                            <Route path="/toppers-list" element={<Toppers />} />
                            <Route path="*" element={<PageNotFound />} />
                          </Routes>
                        </StylesProvider>
                      ) : (
                        <StylesProvider injectFirst>
                          <Routes>
                            <Route path="/" element={<NavTopnav />} exact>
                              <Route path="/" element={<Home />} />
                              <Route path="/about" element={<About />} />
                            </Route>

                            {/* Common */}
                            <Route
                              path="/login"
                              element={
                                <PublicRoute>
                                  <Login />
                                </PublicRoute>
                              }
                            />

                            <Route
                              path="/scholarship-results"
                              element={<ScholarshipResults />}
                            />

                            <Route
                              path="/dashboard/sd"
                              element={
                                <PrivateRoute>
                                  <StudentDashbord />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/allcourse/:id"
                              element={
                                <PrivateRoute>
                                  <StudentVedioPlayer />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/forgot-password"
                              element={<ForgotPassword />}
                            />

                            <Route
                              path="/resetPassword"
                              element={
                                <PrivateRoute>
                                  <PasswordReset />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/profile"
                              element={
                                <PrivateRoute>
                                  <NavbarProfile />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/reset-password"
                              element={
                                <PrivateRoute>
                                  <ResetPassword />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/about/visionandmission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <VisionMission />
                                ) : (
                                  <VisionMission2 />
                                )
                              }
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/courseDetails/:id"
                              element={<CourseDetails />}
                            />

                            <Route
                              path="/about/founder"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <AboutFounder />
                                ) : (
                                  <AboutFounder2 />
                                )
                              }
                            />
                            <Route
                              path="/student-overview"
                              element={<Overview />}
                            />
                            <Route
                              path="/about/overview"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <OverView />
                                ) : (
                                  <OverView2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/dance-and-singing"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <DanceAndSinging />
                                ) : (
                                  <DanceAndSinging2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/canteen"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Canteen />
                                ) : (
                                  <Canteen2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/transport"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Transport />
                                ) : (
                                  <Transport2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route
                              path="/contact-us"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <ContactUs />
                                ) : (
                                  <ContactUs2 />
                                )
                              }
                            />
                            <Route
                              path="/pre-admission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PreAdmission />
                                ) : (
                                  <Addmission2 />
                                )
                              }
                            />
                            <Route
                              path="/results"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Results />
                                ) : (
                                  <Results2 />
                                )
                              }
                            />
                            <Route
                              path="/home-gallery"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <GalleryHome />
                                ) : (
                                  <GalleryHome2 />
                                )
                              }
                            />

                            <Route
                              path="/details/:id"
                              element={<NewsDetails />}
                            />
                            <Route
                              path="/home/events/:id"
                              element={<EventInfo />}
                            />
                            <Route
                              path="/ourachievement/:id"
                              element={<Detail />}
                            />

                            <Route path="/gallery" element={<Gallery />} />

                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />

                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />
                            <Route
                              path="/text-message"
                              element={<TextMessage />}
                            />
                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />

                            {/* <Route
                              path="/reset-password-user"
                              element={
                                <PrivateRoute>
                                  <ResetPasswordUser />
                                </PrivateRoute>
                              }
                            /> */}

                            <Route
                              path="/eventdetails/:id"
                              element={<EventDetails />}
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/student-overview"
                              element={<Overview />}
                            />
                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/canteen"
                              element={<Canteen />}
                            />
                            <Route
                              path="/facilities/transport"
                              element={<Transport />}
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route path="/contact-us" element={<ContactUs />} />

                            <Route path="/results" element={<Results />} />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />

                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />
                            <Route
                              path="/text-message"
                              element={<TextMessage />}
                            />
                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />

                            <Route path="/home-news" element={<HomeNews />} />
                            <Route
                              path="/home-news/:newsId"
                              element={<HomeNewsView />}
                            />
                            <Route
                              path="/home-notice"
                              element={<HomeNotice />}
                            />
                            <Route
                              path="/home-notice/:noticeId"
                              element={<HomeNoticeView />}
                            />
                            <Route
                              path="/home-assignment"
                              element={<AssignmentHome />}
                            />
                            <Route
                              path="/view-assignment"
                              element={<AssignmentView />}
                            />
                            <Route
                              path="/awards-home/:awardsId"
                              element={<AwardsAndAchivments />}
                            />
                            <Route
                              path="/events-home/:eventsId"
                              element={<LatestEventsById />}
                            />
                            <Route path="/toppers-list" element={<Toppers />} />
                            <Route path="*" element={<PageNotFound />} />
                          </Routes>
                        </StylesProvider>
                      )}
                    </Box>
                    <ToastContainer />
                  </div>
                </Box>
              </DrawerWidthContext.Provider>
            </TableDrawer.Provider>
          </UserTypeContext.Provider>
        </RoleUpdateCountContext.Provider>
      </SettingContext.Provider>
    </ThemeProvider>
  );
}

export default App;
