import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router
import { SCHOLARSHIP_RESULTS } from "../results";
import LOGO from "../assets/Images/apuclogo.png";

const candidates = SCHOLARSHIP_RESULTS;

export default function ScholarshipResults() {
  const [candidateId, setCandidateId] = useState("");
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (candidateId) {
      const candidate = candidates.find(
        (c) => c["CANDIDATE ID"] === parseInt(candidateId, 10)
      );
      setResult(candidate || null);
    } else {
      setResult(null);
    }
  };

  const handleReset = () => {
    setCandidateId("");
    setResult(null);
  };

  useEffect(() => {
    setResult(null);
  }, [candidateId]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f5f7fa", // Light grayish-blue
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        py: 4,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          borderRadius: 4,
          boxShadow: 3,
          padding: 4,
        }}
      >
        {/* Header Section with Logo and School Name */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mb: 4, borderBottom: "2px solid #1976d2", pb: 2 }}
        >
          <img
            src={LOGO}
            alt="School Logo"
            style={{ width: "auto", height: "120px" }}
          />
          <Typography variant="h5" fontWeight="bold" sx={{ color: "#1976d2" }}>
            Ashirwad PU Science College
          </Typography>
        </Stack>

        {/* Title and Navigation */}
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ fontWeight: "bold", color: "#424242" }}
          >
            Candidate Marks Search
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Home
          </Button>
        </Stack>

        {/* Input Field */}
        <TextField
          fullWidth
          label="Enter Candidate ID"
          variant="outlined"
          value={candidateId}
          onChange={(e) => setCandidateId(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Buttons */}
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={handleSearch}
            disabled={!candidateId}
          >
            Show Result
          </Button>
          {result ? (
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={handleReset}
            >
              Check Another
            </Button>
          ) : null}
        </Stack>

        {/* Result Section */}
        {result ? (
          <Card sx={{ background: "#e3f2fd", mt: 2 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Candidate ID: {result["CANDIDATE ID"]}
              </Typography>
              <Box>
                <Typography variant="body1" sx={{ color: "#424242" }}>
                  Total Marks: {result.Total?.toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ color: "#424242" }}>
                  Percentage: {result.Percentage?.toFixed(2)}%
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ) : candidateId ? (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            Candidate not found.
          </Typography>
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 2, fontStyle: "italic" }}
          >
            Please enter a Candidate ID and click "Show Result" to view details.
          </Typography>
        )}
      </Container>
    </Box>
  );
}
